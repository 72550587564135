import React from 'react'
import { useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getDetailArticle } from "../../features/article/userArticleSlice"
import { HomeHeader, MathJax } from '../../components'
import { UserImageDisplayer } from '../../components'
import { getDate } from '../../utils/date'
import { ReactComponent as Share } from '../../assets/icons/shareIt.svg'
import { ReactComponent as Logo } from '../../assets/logo/mathustadLogo.svg'
import { MessageType } from '../../types/slices/messages'
import { ReactComponent as Spinner } from '../../assets/icons/spinner_black.svg'


import './articleDetail.css'
import { setMessage } from '../../features/messages/messageSlice'

const ArticleDetail = () => {
    const params = useParams()
    const articleId = params.id
    let isDeletedUser: boolean = false
    let article = useAppSelector((state) => state.userArticle.detailArticle)
    let articleLoading = useAppSelector((state) => state.userArticle.articleLoading)
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        if (articleId) {
            dispatch(getDetailArticle(articleId))
        }
        if (!article[0]?.user) {
            isDeletedUser = true
        }
    }, [])
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    const onShareClick = () => {
        navigator.clipboard.writeText(`https://mathustad.org/article/${article._id}`).
            then((result) => dispatch(setMessage({ content: { message: 'Article link copied to clipboard' }, type: MessageType.success, protected: false }))).
            catch((err) => setMessage({ content: { message: 'Error copying article link to clioboard' }, type: MessageType.success, protected: false }))
    }
    if (articleLoading) {
        return (
            <>
                <HomeHeader backgroundColor='white' />
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner width={120} /></div>
            </>
        )
    }
    if (article.length) {
        article = article[0]
        return (
            <>
                <HomeHeader backgroundColor='white' />
                <div className='articleDetailMainContainer'>
                    <section className='teacherArticleSections'>
                        <div className='articleCartTopSectionLeft'>
                            <UserImageDisplayer imageUrl={isDeletedUser ? '' : article.user.image} imgDim={60} userId={isDeletedUser ? '' : article.user.slug} />
                            <div className='teacherArticleTopSectionLeftInfo'>
                                <span className='teacherArticleTitle'>{article.title}</span>
                                <span className='teacherArticleUsername'>{isDeletedUser ? 'Mathustad Teacher' : article.user.username}</span>
                            </div>
                        </div>
                        <div className='homeCategoryText'>
                            {article.category.name}
                        </div>
                    </section>
                    <MathJax element={article.content} />
                    <span className='teacherArticleCreatedAt'>{getDate(article.createdAt)}</span>
                    <div className='articleShareContainer'>
                        <div className='articleShareLeftPanel'>
                            <p style={{ fontSize: 20 }}>Do you have article</p>
                            <p style={{ fontSize: 24 }}>Share it with friends and enjoy it</p>
                        </div>
                        <div className='articleShareRightPanel'>
                            <div onClick={onShareClick} className='primaryButton articleShareButton'><Share />Share</div>
                            <Logo />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <HomeHeader backgroundColor='white' />
                <div className='articleNotFound' style={{ height: window.outerHeight * 0.35 }}>Article not found</div>
            </>
        )
    }

}
export default ArticleDetail
