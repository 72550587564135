export enum ModalDoneActionTypeEnum{
  'deleted',
  'teacherAdded'
}
export interface SetModalDonePayloadType{
  payload: ModalDoneActionTypeEnum
}
export interface SystemStateType {
  dashboardPath: number;
  registrationSuccess: boolean;
  systemLoading: boolean;
  dashboardModalVisible: boolean;
  dashboardModalComponent: string;
  homeSideBarShow: boolean,
  modalDone: {status:boolean, actionType: ModalDoneActionTypeEnum | null};
}
export interface ModalVisibilityType {
  payload:{
    visibility: boolean,
    component: string
  }
}
export enum ModalComponentType{
  DeleteComponent = 'delete',
  AddTeacherComponent = 'addTeacher'
}

export enum DashboardPages {
  "Publish",
  "My Articles",
  "Drafts",
  "Profile",
  "Articles",
  "Teachers",
  "Register"
}
