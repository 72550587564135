import React from 'react'

import ReactQuill,{Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Buffer } from 'buffer';
import { ImageResize } from 'quill-image-resize-module-ts';

import getAxiosInstance from '../../../utils/axios'
import { BASE_URL, UPLOAD_ARTICLE_IMAGE } from '../../../constant/server_address';
import {ImageBlot} from './image_blot'

const axiosInstance = getAxiosInstance()

const TextEditor =  ({quillRef,onChange, setImageUpload}: any) => {
    const onChangeValue = (e:string) => {
      onChange(e)
    }
    Quill.register(ImageBlot)
    Quill.register("modules/imageResize",ImageResize);

    const imageUpload = async (formData:any) => {
        const response = await axiosInstance.post(UPLOAD_ARTICLE_IMAGE,formData, {        
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return response
    }
    const imageHandler = (e:any) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
            try{
              let file
              if (input.files){
                  setImageUpload(true)
                  file = input.files[0];
                  if (/^image\//.test(file.type)) {
                    const formData = new FormData();
                    formData.append("file", file); // Server just can read image with 'file' name
                    // let buffer = await file.arrayBuffer()
                    // const src = (Buffer.from(buffer).toString('base64'))
                    const res = await imageUpload(formData);
                    let url = res?.data?.url;
                    url = BASE_URL + 'multimedia/articleImage/' + `${url}`
                    editor.insertEmbed(editor.getSelection().index, "image",url);
                    // editor.insertEmbed(editor.getSelection().index, "custom_img",{src:url});
                    // editor.insertEmbed(editor.getSelection().index, "custom_img", {src:`data:image/png;base64,${src}`});
                    setImageUpload(false)
                  } else {
                    // set proper error for unsupported format
                  }
              }
            }
            catch(err){
              setImageUpload(false)
            }
            
        }
    }
    const modules = React.useMemo(() => ({
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', "strike"],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ["blockquote", "code-block"],
            ['image', "link",],
            [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
          ],
          handlers: {
            image: imageHandler
          }
        },
        imageResize: {
          modules: [ 'Resize', 'DisplaySize', 'Toolbar'],
        }
      }), [])

    return (
        <>
          <ReactQuill className='reactQuillEditor' theme="snow"  onChange={onChangeValue} modules={modules}  ref={quillRef}/>
        </>
    )
}

export default TextEditor