import React from 'react'

import { useAppDispatch } from '../../../app/hooks'
import { setModalVisiblity, setModalDone } from '../../../features/systemSlice'
import {ReactComponent as ModalDelete} from '../../../assets/icons/modalDelete.svg'

import { ModalDoneActionTypeEnum } from '../../../types/slices/system'

import './deleteModal.css'

const Modal = () => {
    const dispatch = useAppDispatch()
    const onCancelClick = () => {
        dispatch(setModalVisiblity({visibility:false, component: ''}))
    }
    const onDeleteclick = () => {
        dispatch(setModalVisiblity({visibility:false, component: ''}))
        dispatch(setModalDone(ModalDoneActionTypeEnum.deleted))
    }
    return (
        <div className='deleteModalContainer'>
            <div className='deleteModalTopSection'>
                <ModalDelete/>
                <div className='deleteModalCaution'>
                    <span className='deleteModalCautionTitle'>Do you want to delete it?</span>
                    {/* <span className='deleteModalCautionBody'>Attention : If you delete article, you can't recover it</span> */}
                </div>
            </div>
            <div  className='deleteModalBottomSection'>
                <div onClick={onCancelClick} className='deleteModalCancelBottom'>
                    Cancel
                </div>
                <div onClick={onDeleteclick} className='deleteModalDeleteBottom'>
                    Delete
                </div>
            </div>
        </div>
    )
}
export default Modal