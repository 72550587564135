import { useAppSelector } from "../app/hooks";

import { ComponentMessageTypeBase } from "../types/slices/messages";

export const useComponentMessage = (signature: string, context?: string) => {
  const messages = useAppSelector((state) => state.componentMessage);
  const filterdMessages: ComponentMessageTypeBase[] = [];
  messages.forEach((item: ComponentMessageTypeBase) => {
    if (!context && item.signature == signature) {
      filterdMessages.push(item);
    } else if (
      (context && item.signature == signature, item.context == context)
    ) {
      filterdMessages.push(item);
    }
  });
  return filterdMessages;
};
