export enum responsiveDims {
  mobileMin = 599,
  tabletMin = 1365,
  desktopMin = 1920,
}
export enum monitorType {
  "Mobile" = "mobile",
  "Tablet" = "tablet",
  "Desktop" = "desktop",
  "LargeDesktop" = "largeDesktop",
}
