import React from 'react'

import { useParams } from 'react-router-dom'

import { loginFromResetForm } from '../../features/users/userSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'

const ForgetPassword = () => {
    const dispatch = useAppDispatch()
    const {loginFromReset} = useAppSelector((state) => state.user)
    const {id} = useParams()
    React.useEffect(() => {
        dispatch(loginFromResetForm(id))
    },[])
    return (
        <div>{!loginFromReset ? 'Your token is not correct or expired' : null}</div>
    )
}

export default ForgetPassword