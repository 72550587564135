import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { useAppSelector } from '../../../app/hooks'
import ImageDisplayer from '../imageDisplayer'
import { BASE_URL, IMAGE_LOCATION } from '../../../constant/server_address'

type props = {
    imgDim: number,
    imageUrl: string,
    userId: string
}

const UserImageDisplayer = ({ imgDim, imageUrl, userId }: props) => {
    const currentUserId = useAppSelector((state) => state.user.info.id)
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
    let imageValue
    if (userId?.length > 0 && currentUserId === userId) {
        return (
            <ImageDisplayer imgDim={imgDim} />
        )
    }
    const defaultImage = require('../../../assets/icons/avatar.png')
    if (imageUrl === undefined || imageUrl.length === 0) {
        imageValue = defaultImage
    }
    else {
        imageValue = `${BASE_URL}${IMAGE_LOCATION}${imageUrl}`
    }
    const onLoaded = () => {
        setImageLoaded(true)
    }
    const onError = () => {
        setImageLoaded(true)
        imageValue = defaultImage
    }
    return (
        <>
            {imageLoaded
                ? null
                : <Skeleton circle width={60} height={60} />
            }
            <img onLoad={() => onLoaded()} onError={(err) => onError()} src={imageValue} style={{ width: imgDim, height: imgDim, borderRadius: 80, display: imageLoaded ? 'flex' : 'none' }} />
        </>
    )
}

export default UserImageDisplayer