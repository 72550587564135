import React from 'react'

import { useAppSelector } from '../../../app/hooks'
import { useAppDispatch } from '../../../app/hooks'
import { unsetMessage } from '../../../features/messages/messageSlice'
import { MessageType } from '../../../types/slices/messages'
import {ReactComponent as Close} from '../../../assets/icons/close1.svg'

import './styles.css'

const RADIUS = 16
const Messages = () => {
    const messages = useAppSelector((state) => state.message)
    if (messages.length > 0){
        return <div className='MessagesMainContainer'>{
            messages.map(message => {
                return <Message key={message.id} message={message} />
            })
          }</div>
    }
    return null
}

const Message = ({message}:any) => {
    const dispatch = useAppDispatch()
    
    const closeHandler = (id:string) => {
        dispatch(unsetMessage(id))
    }
    const [percent,setPercent] = React.useState<number>(100)
    const [time, setTime] = React.useState(5)
    const Messagetype = MessageType[message.type]
    const strokeColor = Messagetype == 'fail' ? '#FD3D39': '#8EC23F'
    var circle = document.querySelector(`circle[name='${message.id}']`) as any
    if (circle){
        var circumference = RADIUS * 2 * Math.PI;
        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;
    
        const offset = circumference - percent / 100 * circumference;
        circle.style.strokeDashoffset = `${offset}`;
    }
    const countDown = () => {
        setPercent((prevVal) =>  prevVal - 20)
        setTime((prevVal) =>  prevVal - 1)
    }
    React.useEffect(() => {
        const interval = setInterval(countDown,1000)
        return () => {
            clearInterval(interval)
        }
    },[])
    setTimeout(() => closeHandler(message.id) , 5000)
                return  <div key={message.id} className={`MessagesSubContainer ${Messagetype}`}>
                            <svg
                                className="progress-ring"
                                width="36"
                                height="36"
                            >
                                <circle
                                    name={message.id}
                                    className="progress-ring__circle"
                                    stroke={strokeColor}
                                    strokeWidth="4"
                                    fill="transparent"
                                    r={RADIUS}
                                    cx="18"
                                    cy="18"
                                >
                                </circle>
                                <text x="14" y="23">{time}</text>
                            </svg>
                            <span style={{wordWrap:'break-word',width:'80%'}}>{message.content}</span>
                            <span onClick={() => closeHandler(message.id)}><Close/></span> 
                        </div>
}

export default Messages