import React from 'react'
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom'

import { getTeacherArticles, deleteTeacherArticle, articleUpdate } from '../../features/article/teacherArticleSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Loader, ImageDisplayer } from '../../components'
import {ReactComponent as Trash} from '../../assets/icons/trash.svg'
import { getDate } from '../../utils/date'
import { setModalVisiblity } from '../../features/systemSlice'
import {ReactComponent as Spinner} from '../../assets/icons/spinner_black.svg'
 
import './articleList.css'

import { DashboardOutletContextType } from '../../types/dashboard'
import uuid4 from 'uuid4'

const ArticlesShow = () => {
    const [deletedPost,setdeletedPost] = React.useState<string>('')
    const {mar} = useOutletContext<DashboardOutletContextType>()
    const [searchedKey, setSearchedKey] = React.useState('')
    const location = useLocation()
    const splittedLocation = location.pathname.split('/')
    const {dashboardModalVisible, modalDone} = useAppSelector((state) => state.system)
    const teacherArticle = useAppSelector((state) => state.teacherArticle)
    const dispatch = useAppDispatch()
    let isPublished = React.useRef(true)
    const requestHandler = async (isPublishedPage:boolean|undefined) => {
        if (isPublishedPage !== undefined){
            dispatch(getTeacherArticles({published:isPublishedPage}))
        }
    }
    if (splittedLocation[2] === 'drafts'){
        isPublished.current = false
    }
    else{
        isPublished.current = true
    }
    const onSearchPressButton = (e:KeyboardEvent) => {
        if (e.key == 'Enter'){
            if (inputRef.current?.value !== undefined){
                dispatch(getTeacherArticles({published:isPublished.current,searchKey: inputRef.current?.value}))    
            }
        }
    }
    const onSearhedBoxChange = (e:any) => {
        setSearchedKey(e.currentTarget.value)
    }
    React.useEffect(() => {
        requestHandler(isPublished.current)
    },[isPublished.current])
    const inputRef = React.useRef<HTMLInputElement>(null)
    React.useEffect(() => {
        inputRef.current?.addEventListener('keypress',onSearchPressButton)
        return () => {
            inputRef.current?.removeEventListener('keypress',onSearchPressButton)
        }
    })
    React.useEffect(() => {
        if (inputRef.current){
            inputRef.current.value = ""
        }
    },[location])
    React.useEffect(() => {
        if (modalDone.status){
            dispatch(deleteTeacherArticle({id:deletedPost}))
        }
    },[modalDone])
    if (teacherArticle.loading){
        return (
            <div style={{marginLeft:mar,height:'100%',display:'flex',justifyContent:'center', alignItems:'center'}}><Spinner width={120} /></div>
        )
    }
    else{
        return (
            <div style={{marginLeft:mar,height:'100%',marginTop:'1%',overflowY:dashboardModalVisible?'hidden':'visible'}} className='teacherArticlesPageMainContainer'>
                <div style={{width:'95%',display:'flex',flexDirection:'column'}}>
                    <input className='teacherArticlesInput' placeholder='Search article' ref={inputRef} onChange={onSearhedBoxChange} value={searchedKey}/>
                    <div className='teacherArticlesListMainContainer'>
                        {teacherArticle.content.length>0 
                            ? (teacherArticle.content.map((element:any) => <ArticleCard data={element} key={element._id} publishedPage={isPublished.current} setdeletedPost={setdeletedPost} />))
                            :(<div className='teacherArticleListNotFound' style={{height:window.outerHeight/2}}>There is no article</div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}
type ArticleCardProps = {
    data: any,
    publishedPage: boolean;
    setdeletedPost: React.Dispatch<React.SetStateAction<string>>
}
export const ArticleCard = ({data, publishedPage,setdeletedPost}:ArticleCardProps) => {
    const navigate = useNavigate()
    const imgDim = 60
    const user = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()
    const onDeleteHandler = (id:string) => {
        dispatch(setModalVisiblity({visibility:true, component: 'delete'}))
        setdeletedPost(id)
    }
    const onPublishedStateChange = (id:string) => {
        dispatch(articleUpdate({id:id, value:{isPublished: !publishedPage} }))
    }
    const onEditClickHandler = (id:string) => {
        navigate('/dashboard',{state:{id}})
    }

    return (
        <div className='teacherArticlesCardContainer'>
            <section className='teacherArticleSections'>
                <div className='teacherArticleTopSectionLeft'>
                    <ImageDisplayer imgDim={imgDim} />
                    <div className='teacherArticleTopSectionLeftInfo'>
                        <span className='teacherArticleTitle'>{data.title}</span>
                        <span className='teacherArticleUsername'>{user.info.username}</span>
                    </div>
                </div>
                    <Trash onClick={() => onDeleteHandler(data._id)} style={{cursor:'pointer'}}/>
            </section>
            <section className='teacherArticleSections'>
                <span className='teacherArticleSummary'>{data.summary}</span>
            </section>
            <section className='teacherArticleSections'>
                <span className='teacherArticleCreatedAt'>{getDate(data.createdAt)}</span>
                <div className='hashtagsContainer'>
                    {data.hashtags.map((element:any) => {
                       return <span key={uuid4()} style={{marginLeft:20}}>{element}</span>
                    })}
                </div>
                <div style={{display:'flex', width:'30%',justifyContent:'space-between'}}>
                    <button style={{width:'45%'}} className='secondaryButton articleListButton' onClick={publishedPage ? () => onPublishedStateChange(data._id):() => onEditClickHandler(data._id)}>{publishedPage ? 'Move To draft' : 'Edit'}</button>
                    <button style={{width:'45%'}} className='primaryButton' onClick={publishedPage ? () => onEditClickHandler(data._id) :() => onPublishedStateChange(data._id) }>{publishedPage ? 'Edit' : 'Publish'}</button> 
                </div>
            </section>
        </div>
    )
}

export default ArticlesShow