import React, { ReactElement } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

type props = {
    count: number
}
const ArticleCardSkeleton = ({count}:props):ReactElement => {
    let arr:number[] = []
    for (var i=0; i<count; i++){
        arr.push(i)
    }
    return (
        <>
            {arr.map((item) => {
                return <ArticleCardSkeletonElement key={item} />
            })}
        </>
    )
}

const ArticleCardSkeletonElement = () => {
    return (
            <div className='teacherArticlesCardContainer' style={{cursor:'pointer'}}>
                <section className='teacherArticleSections'>
                    <div className='articleCartTopSectionLeft'>
                        <Skeleton circle={true} width={60} height={60} />
                        <div className='teacherArticleTopSectionLeftInfo'>
                            <Skeleton count={2} style={{width:50,height:10}} />
                        </div>
                    </div>
                    <div className='homeCategoryText'>
                        <Skeleton count={1} style={{width:80,height:10}}/>
                    </div>
                </section>
                <section className='teacherArticleSections'>
                    <span className='teacherArticleSummary'><Skeleton count={3} style={{width:5000,height:10}}/></span>
                </section>
                <section className='teacherArticleSections'>
                    <span className='teacherArticleCreatedAt'><Skeleton count={1} style={{width:80,height:10}}/></span>
                </section>
            </div>
    )
}

export default ArticleCardSkeleton


