import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/users/userSlice";
import messageReducer from "../features/messages/messageSlice";
import systemReducer from "../features/systemSlice";
import componentMessageReducer from "../features/messages/componentMessageSlice";
import userArticleReducer from "../features/article/userArticleSlice";
import teacherArticleReducer from "../features/article/teacherArticleSlice";
import AdminReducer from "../features/users/adminSlice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    message: messageReducer,
    system: systemReducer,
    componentMessage: componentMessageReducer,
    userArticle: userArticleReducer,
    teacherArticle: teacherArticleReducer,
    admin: AdminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "userArticleSlice/fetch/fulfilled",
          "userArticleSlice/search/fulfilled",
          "teacherArticle/fetch/fulfilled",
          "teacherArticle/delete/fulfilled",
          "userArticleSlice/fetch/all/fulfilled",
        ],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
