import React from 'react'
import { Outlet } from 'react-router-dom'

import { WidthLimiter, DashboardSidebar, DashboardHeader } from '../components'
import {Modal} from '../components'

import config from '../config.json'


const AdminDashboardLayout = () => {
    const [width,setWidth] = React.useState(config.Dashboard.LeftPanelWidth)
    const sideBarCollapse = (collapse:boolean) => {
        if (collapse){
            setWidth(config.Dashboard.LeftPanelWidth)
        }
        else{
            setWidth(config.Dashboard.LeftCollapsedPanelWidth)

        }
    }
    return(
        <WidthLimiter>
            <div style={{display:'flex',width:'100%',height:'100vh'}}>
                <Modal />
                <DashboardSidebar width = {width} sideBarCollapse={sideBarCollapse} />
                <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
                    <DashboardHeader mar={width} />
                    <Outlet context={{'mar':width}}/>
                </div>  
            </div>   
        </WidthLimiter>
    )
}

export default AdminDashboardLayout