import React from 'react'

import { useOutletContext } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {ReactComponent as Add} from '../../assets/icons/add.svg'
import { getTeachersList, deleteTeacher } from '../../features/users/adminSlice'
import {ReactComponent as Trash} from '../../assets/icons/trash.svg'
import { setModalVisiblity, setRegisterFalse, unsetModalDone } from '../../features/systemSlice'
import {ReactComponent as Spinner} from '../../assets/icons/spinner_black.svg'

import { DashboardOutletContextType } from '../../types/dashboard'
import { ModalComponentType, ModalDoneActionTypeEnum } from '../../types/slices/system'

import "./teacher.css"
import { UserImageDisplayer } from '../../components'

const AdminTeacher = () => {
    const {mar} = useOutletContext<DashboardOutletContextType>()
    const dispatch = useAppDispatch()
    const { modalDone } = useAppSelector((state) => state.system)
    const {teacherList,teacherListLoading} = useAppSelector((state) => state.admin)
    const [deletedUser, setDeletedUser] = React.useState<string>('')
    React.useEffect(() => {
        dispatch(getTeachersList())
    },[])
    React.useEffect(() => {
        if (modalDone.status && modalDone.actionType === ModalDoneActionTypeEnum.deleted){
            dispatch(deleteTeacher(deletedUser))
            dispatch(unsetModalDone())
        }
        if (modalDone.status && modalDone.actionType === ModalDoneActionTypeEnum.teacherAdded){
            dispatch(setModalVisiblity({visibility:false, component: ''}))
            dispatch(unsetModalDone())
            dispatch(setRegisterFalse())
        }
    },[modalDone])
    if (teacherListLoading){
        return (
            <div style={{marginLeft:mar,height:'100%'}} className="AdminTeacherListMainContainer">
                <Spinner width={80} />
            </div>
        )
    }
    const onTeacherAddClick = () => {
        dispatch(setModalVisiblity({visibility:true,component:ModalComponentType.AddTeacherComponent}))
    }
    return (
        <div style={{marginLeft:mar,height:'100%'}} className="AdminTeacherListMainContainer"> 
            <div className='primaryButton addTeacherButton' onClick={onTeacherAddClick}><Add/>Add Teacher</div>
            <div className='AdminTeacherListTableMainContainer'>
                <div className="AdminTeacherListTableHeaderContainer">
                    <div className='AdminTeacherListColumn1 AdminTeacherTableHeader'></div>
                    <div className='AdminTeacherListColumn2 AdminTeacherTableHeader'></div>
                    <div className='AdminTeacherListColumn3 AdminTeacherTableHeader'>Username</div>
                    <div className='AdminTeacherListColumn4 AdminTeacherTableHeader'>Email</div>
                    <div className='AdminTeacherListColumn5 AdminTeacherTableHeader'>Field</div>
                    <div className='AdminTeacherListColumn6 AdminTeacherTableHeader'></div>
                </div>
                <div className="AdminTeacherListTableContentContainer">
                {teacherList.length === 0 && (
                    <div className='noTeacherErrorMessage'>There is no teacher yet!</div>
                )}
                {teacherList.length > 0  && teacherList.map((item:any,index) => {
                    return (
                       <TableContentElement 
                            item={item} 
                            index={index} 
                            setDeletedUser={setDeletedUser} 
                            key={item.slug} 
                            isLastElement = {index === teacherList.length - 1}
                        />
                    )
                })}
                </div>
            </div>
        </div>
    )
}
type TableContentElement = {
    item: {username:string,email:string,slug:string,image:string},
    index: number
    setDeletedUser: React.Dispatch<React.SetStateAction<string>>
    isLastElement: boolean
}
const TableContentElement = ({item,index,setDeletedUser, isLastElement}:TableContentElement ) => {
    const dispatch = useAppDispatch()
    const onDeleteTeacherClick = () => {
        dispatch(setModalVisiblity({visibility:true,component:ModalComponentType.DeleteComponent}))
        setDeletedUser(item.slug)
    }
    return (
        <div className='AdminTeacherListTableContentElementContainer'>
            <div className='AdminTeacherListColumn1 AdminTeacherListTableContentElement'>{index + 1}</div>
            <div className={`AdminTeacherListColumn2 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}>
                {<UserImageDisplayer imageUrl={item.image} imgDim={40} userId={item.slug}/>}
            </div>
            <div className={`AdminTeacherListColumn3 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}>{item.username}</div>
            <div className={`AdminTeacherListColumn4 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}>{item.email}</div>
            <div className={`AdminTeacherListColumn5 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}>Math</div>
            <div className={`AdminTeacherListColumn6 AdminTeacherListTableContentElement`}>
                <Trash style={{width:20,height:20,cursor:'pointer'}} onClick={onDeleteTeacherClick}/>
            </div>
        </div>
    )
}
export default AdminTeacher