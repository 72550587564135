import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { GeneralNavigatorButton } from '../../..'
import { logout } from '../../../../features/users/userSlice'
import { ImageDisplayer } from '../../..'
import { useValueSelector, useGetDeviceType } from '../../../../hooks/dims'
import { ReactComponent as DownWardArrow } from '../../../../assets/icons/downward-arrow.svg'
import { monitorType } from '../../../../constant/dims'
import { ReactComponent as Logout } from '../../../../assets/icons/logout.svg'
import { ReactComponent as Dashboard } from '../../../../assets/icons/dashboard.svg'
import { ReactComponent as BlackLogo } from '../../../../assets/logo/logo.svg'
import { ReactComponent as WhiteLogo } from '../../../../assets/logo/logo_white.svg'
import { ReactComponent as Menu } from '../../../../assets/icons/menu.svg'
import { homeSideBarVisibility } from '../../../../features/systemSlice'

import './style.css'

type props = {
    backgroundColor: string,
    color?: string
}

const HomeHeader = ({ backgroundColor, color = 'black' }: props) => {
    const user = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const deviceType = useGetDeviceType()

    const imageDim = useValueSelector(60, 40, 40)
    const imgDim = React.useRef<number>(imageDim)

    const { isAuthenticated } = useAppSelector((state) => state.user)
    const [dropdown, setDropdown] = React.useState(false)

    const logoutHandler = () => {
        dispatch(logout())
    }
    const dashboardOnClick = () => {
        navigate('/dashboard')
    }
    const toggleDropDown = () => {
        setDropdown(!dropdown)
    }
    const dropDownButtonRef = React.useRef<HTMLInputElement>(null)
    const outsideClickHandler = (e: any) => {
        if (!dropDownButtonRef.current?.contains(e.target)) {
            setDropdown(false)
        }
    }
    React.useEffect(() => {
        if (deviceType !== monitorType.Mobile) {
            document.addEventListener('click', outsideClickHandler)
        }
        return () => {
            document.removeEventListener('click', outsideClickHandler)
        }
    }, [])
    const onMenuClick = () => {
        dispatch(homeSideBarVisibility(true))
    }
    if (deviceType === monitorType.Desktop || deviceType === monitorType.LargeDesktop || deviceType === monitorType.Tablet) {
        return (
            <div className='homeHeaderMainContainer' style={{ backgroundColor: backgroundColor }}>
                <div className='homeHeaderLeftPanel'>
                    {
                        color == 'black'
                            ? <BlackLogo width={40} />
                            : <WhiteLogo width={40} />
                    }
                    <div style={{ color: color }}>Mathustad</div>
                </div>
                <div className='homeHeaderRightPanel'>
                    <span><Link style={{ color: color }} to='/'>Home</Link></span>
                    <span><Link style={{ color: color }} to='/articles'>Articles</Link></span>
                    {
                        isAuthenticated
                            ?
                            <>
                                <div className="homeHeaderUserProfile">
                                    <ImageDisplayer imgDim={imgDim.current} />
                                    <span style={{ color: color }} className='homeHeaderUsername'>{user.info.username}</span>
                                    <span onClick={toggleDropDown} ref={dropDownButtonRef}><DownWardArrow style={{ cursor: 'pointer' }} /></span>
                                    <span style={{ display: dropdown ? 'flex' : 'none' }} className='dropdownContainer'>
                                        <span onClick={dashboardOnClick} className='dropdownElement'><Dashboard />Dashboard</span>
                                        <span onClick={logoutHandler} className='dropdownElement'><Logout />Logout</span>
                                    </span>
                                </div>
                            </>
                            :
                            (
                                <>
                                    <span ><Link to='/auth/login' style={{ color: color }}>Login</Link></span>
                                    {/* <GeneralNavigatorButton
                                        title='Register'
                                        path='/auth/register'
                                        width = {128}
                                        height = {40}
                                    />   */}
                                </>
                            )
                    }
                </div>
            </div>
        )
    }
    else if (deviceType === monitorType.Mobile) {
        return (
            <div className='homeHeaderMainContainer' style={{ backgroundColor: backgroundColor }}>
                <Menu onClick={onMenuClick} name="menuButton" />
                <div className='homeHeaderLeftPanel'>
                    <WhiteLogo width={20} height={20} />
                    <div>Mathustad</div>
                </div>
            </div>
        )
    }
    return null
}

export default HomeHeader