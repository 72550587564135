import { setComponentMessage } from "../features/messages/componentMessageSlice";
import { MessageType } from "../types/slices/messages";

interface CoreValidatorType {
  isValid: (input: string | undefined) => boolean;
  message: () => { signature: string; context: string; messages: string[] };
  messages: string[];
}

export class CoreValidator {
  constructor(private validator: CoreValidatorType, private dispatch: any) {}
  start(value: string | undefined) {
    const validationResult = this.validator.isValid(value);
    const messageObj = this.validator.message();
    const messages = messageObj.messages;
    for (const message of messages) {
      this.dispatch(
        setComponentMessage({
          signature: messageObj.signature,
          context: messageObj.context,
          type: MessageType.fail,
          content: message,
        })
      );
    }
    return validationResult;
  }
}

export function isEmpty(value: string | undefined) {
  if (value == undefined || !value || value.length <= 0) {
    return true;
  }
  return false;
}

export function sanitize(value: string): void {
  value.trim().toLowerCase();
}
export function isEmail(value: string): boolean {
  const isEmail = value?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  if (isEmail?.[0]) {
    return true;
  }
  return false;
}

export class IsEmailValidator implements CoreValidatorType {
  messages: string[] = [];
  constructor(private signature: string, private context: string) {}
  isValid = (value: string | undefined) => {
    if (isEmpty(value)) {
      this.messages.push("Email can not be empty");
      return false;
    }
    sanitize(value as string);
    if (!isEmail(value as string)) {
      this.messages.push("Email is not on proper format");
      return false;
    }
    return true;
  };
  message = () => {
    return {
      signature: this.signature,
      context: this.context,
      messages: this.messages,
    };
  };
}

export class RequiredValidator implements CoreValidatorType {
  constructor(
    private signature: string,
    private context: string,
    private custom_message?: string
  ) {}
  messages: string[] = [];

  isValid = (value: string | undefined) => {
    if (isEmpty(value)) {
      this.messages.push(
        this.custom_message ? this.custom_message : "This field is required!"
      );
      return false;
    }
    return true;
  };
  message = () => {
    return {
      signature: this.signature,
      context: this.context,
      messages: this.messages,
    };
  };
}
