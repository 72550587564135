import React from 'react'

import { ReactComponent as Logo } from '../../../../assets/logo/logo.svg'
import { ReactComponent as WhiteLogo } from '../../../../assets/logo/logo_white.svg'

import { monitorType } from '../../../../constant/dims'
import { useGetInnerDim } from '../../../../hooks/dims'
import { useGetDeviceType } from '../../../../hooks/dims'
import './style.css'


type props = {
    text: string
}

const NonMobile = ({ text }: props) => {
    const { height } = useGetInnerDim()
    return (
        <div className='loginPageCover' style={{ paddingTop: 0.18 * height }}>
            <div className='loginPageCoverHeader'>
                <WhiteLogo style={{ width: 49, height: 40 }} />
                <span className='loginPageCovertitle'>Mathustad</span>
            </div>
            <p className='loginPageCoverWelcome'>Welcome to Mathustad</p>
            {/* <p className='loginPageCoverMessage'>{text}</p> */}
            <div style={{ marginTop: 10 }}>
                <a href="https://www.freepik.com/free-vector/3d-abstract-background-with-paper-cut-shape_18546821.htm">Image by GarryKillian</a> on Freepik
            </div>
        </div>
    )
}
const Mobile = () => {
    return (
        <>
            <div className='loginPageCoverMobileContainer'>
                <Logo />
                <span className='loginPageCovertitle'>Mathustad</span>
            </div>
        </>
    )
}

const AuthCover = ({ text }: props) => {
    const deviceType = useGetDeviceType()
    if (deviceType !== monitorType.Mobile) {
        return (
            <NonMobile text={text} />
        )
    }
    else {
        return (
            <Mobile />
        )
    }
}

export default React.memo(AuthCover)