import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import axios from 'axios';

import { ProtectedRoute, AdminProtectedRoute, HomeLayout, DashboardLayout, AdminDashboardLayout } from './layouts';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { checkFromLocalStorage, tokenError } from './features/users/userSlice'

import {
  Dashboard,
  ArticleDetail,
  TeacherArticlesList,
  TeacherProfileEdit,
  TeacherRegister,
  Articles,
  TeacherLogin,
  NotFound,
  ForgetPassword,
  AdminTeacher,
  AdminArticles,
  AdminTeacherRegister,
  HomePage
} from './pages';

import { Messages } from './components'

function App() {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(checkFromLocalStorage())
    // registerInterceptor(dispatch)
  }, [])
  // if (isLoading){
  //   return <Spinner/>
  // }

  axios.interceptors.response.use(null, (err) => {
    if (err.response.data.message === 'Token expired') {
      localStorage.removeItem('token')
      dispatch(tokenError())
    }
    else {
      return Promise.reject(err);
    }
  });
  return (
    <BrowserRouter>
      <Messages />
      <Routes>
        <Route path='/' element={<HomeLayout />}>
          <Route index element={<HomePage />} />
          <Route path='articles' element={<Articles />} />
          <Route path='article/:id' element={<ArticleDetail />} />
        </Route>
        <Route path='/dashboard' element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path='published' element={<TeacherArticlesList />} />
          <Route path='drafts' element={<TeacherArticlesList />} />
          <Route path='profile' element={<TeacherProfileEdit />} />
        </Route>
        <Route path='/admin/dashboard' element={<ProtectedRoute><AdminProtectedRoute><AdminDashboardLayout /></AdminProtectedRoute></ProtectedRoute>}>
          <Route path='teachers' element={<AdminTeacher />} />
          <Route path='articles' element={<AdminArticles />} />
          <Route path='register' element={<AdminTeacherRegister />} />
        </Route>
        <Route path='/auth'>
          <Route path='login' element={<ProtectedRoute reversed={true}><TeacherLogin /></ProtectedRoute>} />
          {/* <Route path='register' element={<ProtectedRoute reversed={true}><TeacherRegister /></ProtectedRoute>} /> */}
          <Route path='validation/:id' element={<ProtectedRoute reversed={true}><ForgetPassword /></ProtectedRoute>} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
