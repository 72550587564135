import React from "react";
import { Link } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css'

import { ReactComponent as ArrowIcon } from "../../../../assets/icons/dashSidebar/arrow.svg";
import { ReactComponent as Profile } from "../../../../assets/icons/dashSidebar/profile.svg";
import { ReactComponent as Article } from "../../../../assets/icons/dashSidebar/article.svg";
import { ReactComponent as Publish } from "../../../../assets/icons/dashSidebar/publish.svg";
import { ReactComponent as Drafts } from "../../../../assets/icons/dashSidebar/drafts.svg";
import { ReactComponent as Teachers } from '../../../../assets/icons/dashSidebar/teachers.svg'
import { ReactComponent as Register } from '../../../../assets/icons/dashSidebar/register.svg'
import { ReactComponent as Exit } from "../../../../assets/icons/dashSidebar/exit.svg";
import { ReactComponent as Logo } from "../../../../assets/logo/logo_white.svg"
import { colors } from "../../../../constant/colors";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { setDashboardPath } from "../../../../features/systemSlice";
import { useGetDeviceType } from "../../../../hooks/dims";
import { monitorType } from "../../../../constant/dims";
import { ImageDisplayer } from '../../..'

import './style.css'

type DashboardSidebarType = {
    width: number;
    sideBarCollapse: (collapse: boolean) => void
}

type DashboardSidebarChildType = {
    collapse: boolean;
}
type DashboardSidebarChildNavigatorItemType = {
    collapse: boolean;
    id: number
    active: number
    activeHandler: (id: number) => void
    title: string
    link: string
    icon: any
    isExit?: boolean
}

const DashboardSidebar = (props: DashboardSidebarType) => {
    const { width, sideBarCollapse } = props
    const devicetype = useGetDeviceType()
    const [collapse, setCollapse] = React.useState<boolean>(false)
    const [activeClick, setActiveClick] = React.useState<boolean>(false)
    const collapseHandler = () => {
        sideBarCollapse(collapse)
        setCollapse(!collapse)
        setActiveClick(true)
    }
    React.useEffect(() => {
        if (devicetype === monitorType.Tablet) {
            collapseHandler()
        }
    }, [])
    if (devicetype === monitorType.Tablet || devicetype === monitorType.Desktop || devicetype === monitorType.LargeDesktop) {
        return (
            <div
                data-collapse={collapse}
                data-clickactivated={activeClick}
                style={{ width, backgroundColor: colors.pagesSharedComponent, height: window.outerHeight }}
                className='dashboardSidebarMainContainer'>
                <div style={{ transform: collapse ? 'rotate(180deg)' : 'rotate(0deg)', backgroundColor: colors.pagesSharedComponent }} className="collapse_button" onClick={collapseHandler}>
                    <ArrowIcon />
                </div>
                <Header collapse={collapse} />
                <Info collapse={collapse} />
                <Navigator collapse={collapse} />
            </div>
        )
    }
    else if (devicetype === monitorType.Mobile) {
        <div style={{ width: '100%', backgroundColor: 'red', height: 20 }}>sldnflk</div>
    }
    return null
}

const Header = (props: DashboardSidebarChildType) => {
    const { collapse } = props
    return (
        <Link to='/' className='dashboardSidebarTitleContainer' replace={true}>
            <Logo style={{ width: 32, height: 32 }} />
            {collapse
                ? null
                : <div className='dashboardSidebarHeader'>Mathustad</div>
            }
        </Link>
    )
}

const Info = (props: DashboardSidebarChildType) => {
    const { collapse } = props
    const user = useAppSelector((state) => state.user)
    const imgDim = collapse ? 60 : 120
    return (
        <div className='dashboardSidebarInfoContainer'>
            <ImageDisplayer imgDim={imgDim} />
            {collapse
                ? null
                :
                (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ fontSize: '24px', fontWeight: '600' }}>{user.info.username}</span>
                        <span style={{ fontSize: '16px', fontWeight: '400' }}>{user.info.email}</span>
                    </div>
                )
            }
        </div>
    )
}

const NavigatorItem = (props: DashboardSidebarChildNavigatorItemType) => {
    const { collapse, id, active, activeHandler, title, link, icon, isExit } = props
    return (
        <div className='DashboardElementSubContainer'>
            {active == id
                ? <div className='dashboardActiveIndicator' />
                : null
            }
            <Link
                to={link}
                className="dashboardLink"
                onClick={() => activeHandler(isExit ? 0 : id)}
            >
                {icon} {collapse ? null : title}
            </Link>
        </div>
    )
}

const Navigator = (props: DashboardSidebarChildType) => {
    const { collapse } = props
    const dispatch = useAppDispatch()
    const activeTab = useAppSelector((state) => state.system.dashboardPath)
    const { isAdmin } = useAppSelector((state) => state.user.info)

    const activeHandler = (id: number) => {
        dispatch(setDashboardPath(id))
    }
    const getIconsDims = () => {
        return { width: 26, height: 26 }
    }
    return (
        <div className='DashboardElementMainContainer'>
            <NavigatorItem
                collapse={collapse}
                id={0}
                active={activeTab}
                activeHandler={activeHandler}
                title='Publish'
                link='/dashboard'
                icon={<Publish style={getIconsDims()} />}
            />
            <NavigatorItem
                collapse={collapse}
                id={1}
                active={activeTab}
                activeHandler={activeHandler}
                title='My Articles'
                link='/dashboard/published'
                icon={<Article style={getIconsDims()} />}
            />
            <NavigatorItem
                collapse={collapse}
                id={2}
                active={activeTab}
                activeHandler={activeHandler}
                title='Drafts'
                link='/dashboard/drafts'
                icon={<Drafts style={getIconsDims()} />}
            />
            <NavigatorItem
                collapse={collapse}
                id={3}
                active={activeTab}
                activeHandler={activeHandler}
                title='Profile'
                link='/dashboard/profile'
                icon={<Profile style={getIconsDims()} />}
            />
            {isAdmin
                ?
                (
                    <>
                        <NavigatorItem
                            collapse={collapse}
                            id={4}
                            active={activeTab}
                            activeHandler={activeHandler}
                            title='Articles'
                            link='/admin/dashboard/articles'
                            icon={<Teachers style={getIconsDims()} />}
                        />
                        <NavigatorItem
                            collapse={collapse}
                            id={5}
                            active={activeTab}
                            activeHandler={activeHandler}
                            title='Teachers'
                            link='/admin/dashboard/teachers'
                            icon={<Teachers style={getIconsDims()} />}
                        />
                        <NavigatorItem
                            collapse={collapse}
                            id={6}
                            active={activeTab}
                            activeHandler={activeHandler}
                            title='Register'
                            link='/admin/dashboard/register'
                            icon={<Register style={getIconsDims()} />}
                        />

                    </>
                )
                : null
            }
            <div style={{ marginTop: 10 }}>
                <NavigatorItem
                    collapse={collapse}
                    id={7}
                    active={activeTab}
                    activeHandler={activeHandler}
                    title='Exit'
                    link='/'
                    icon={<Exit style={getIconsDims()} />}
                    isExit={true}
                />
            </div>
        </div>

    )
}

export default DashboardSidebar