import React from 'react'

import { ComponentMessageType, ComponentMessageTypeBase } from '../../../types/slices/messages'
import { unsetComponentMessage } from '../../../features/messages/componentMessageSlice'
import {ReactComponent as Caution} from '../../../assets/icons/caution.svg'
import { useAppDispatch } from '../../../app/hooks'

import { MessageType } from '../../../types/slices/messages'

import './style.css'

type props = {
    messages: ComponentMessageType,
    limit?: number
}

const ComponentMessage = (props:props) => {
    const dispatch = useAppDispatch()
    const {messages} = props
    if (messages.length > 0){
        return <div style={{marginTop:8}}>{
            messages.map((message: ComponentMessageTypeBase) => {
                setTimeout(() => dispatch(unsetComponentMessage(message.id)) , 10000)
                return  <div key={message.id}>
                            <span className={`componentMessage${MessageType[message.type]}`}><Caution /> {message.content}</span>
                        </div>
            })
          }</div>
    }
    return null
}

export default ComponentMessage
