import { AxiosError } from "axios";

export enum MessageType {
  "warn",
  "success",
  "fail",
}

export interface MessagePayloadType {
  content: AxiosError;
  protect: boolean;
  type?: MessageType;
}

export interface MessageStateTypeBase {
  id: string;
  content: string;
  protect: boolean;
  type?: MessageType;
}

export interface MessageStateType extends Array<MessageStateTypeBase> {}

export interface ComponentMessageTypeBase {
  id: string;
  content: string;
  type: MessageType;
  signature: string;
  context?: string;
}
export interface ComponentMessageType extends Array<ComponentMessageTypeBase> {}

export interface ComponentMessagePayloadType {
  content: AxiosError;
  context: string;
  signature: string;
}
