import React from 'react'
import { useOutletContext } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getRegisterRequest, approveTeacherRequest, rejectTeacherRequest } from '../../features/users/adminSlice'
import {ReactComponent as Tick} from '../../assets/icons/tick.svg'
import {ReactComponent as Trash} from '../../assets/icons/trash.svg'

import { DashboardOutletContextType } from '../../types/dashboard'

import "./register.css"

const AdminTeacherRegister = () => {
    const {mar} = useOutletContext<DashboardOutletContextType>()
    const dispatch = useAppDispatch() 
    const {registerRequest, adminSliceWaiting} = useAppSelector((state) => state.admin)
    React.useEffect(() => {
        dispatch(getRegisterRequest())
    },[])
    return (
        <div style={{marginLeft:mar,height:'100%'}} className="TeacherRegistrationListContainer"> 
            <div className={`snackbar`} data-visible={adminSliceWaiting}>Please wait...</div>
            <div className='AdminTeacherListTableMainContainer'>
                <div className="AdminTeacherListTableHeaderContainer">
                        <div className='AdminTeacherRegisterColumn1 AdminTeacherTableHeader'></div>
                        <div className='AdminTeacherRegisterColumn2 AdminTeacherTableHeader'>Username</div>
                        <div className='AdminTeacherRegisterColumn3 AdminTeacherTableHeader'>Email</div>
                        <div className='AdminTeacherRegisterColumn4 AdminTeacherTableHeader'>Field</div>
                        <div className='AdminTeacherRegisterColumn5 AdminTeacherTableHeader'>Action</div>
                </div>
                {registerRequest.map((item,index) => <TableContentElement item={item} index={index} isLastElement={false}/>)}
            </div>
        </div>
    )
}

type TableContentElement = {
    item: any,
    index: number
    // setDeletedUser: React.Dispatch<React.SetStateAction<string>>
    isLastElement: boolean
}
const TableContentElement = ({index, isLastElement, item}:TableContentElement ) => {
    const dispatch = useAppDispatch() 
    const {adminSliceWaiting} = useAppSelector((state) => state.admin)
    const approveTeacher = () => {
        if (!adminSliceWaiting){
            dispatch(approveTeacherRequest(item.slug))
        }
    }
    const rejectTeacher = () => {
        if (!adminSliceWaiting){
            dispatch(rejectTeacherRequest(item.slug))
        }
    }
    return (
        <div className='AdminTeacherListTableContentElementContainer'>
            <div className='AdminTeacherRegisterColumn1 AdminTeacherListTableContentElement'>{index + 1}</div>
            <div className={`AdminTeacherRegisterColumn2 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}><span className="tableTextContainer">{item.username}</span></div>
            <div className={`AdminTeacherRegisterColumn3 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}><span className="tableTextContainer">{item.email}</span></div>
            <div className={`AdminTeacherRegisterColumn4 AdminTeacherListTableContentElement ${!isLastElement ? 'tableBorder': 'null'}`}><span className="tableTextContainer">{item.categories[0].name}</span></div>
            <div className={`AdminTeacherRegisterColumn5 AdminTeacherListTableContentElement`}>
                <Tick width={24} height={24} onClick={approveTeacher}/>
                <Trash width={18} height={24} onClick={rejectTeacher}/>
            </div>
        </div>
    )
}
export default AdminTeacherRegister