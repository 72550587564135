import React from 'react'
import { Outlet } from 'react-router-dom'

import { HomeFooter, WidthLimiter, HomeSideBar } from '../components'
import { useAppSelector, useAppDispatch } from '../app/hooks'

const HomeLayout = () => {
    const {homeSideBarShow} = useAppSelector((state) => state.system)
    return(
        <WidthLimiter>
            <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <HomeSideBar show={homeSideBarShow}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Outlet/>
                </div>  
                <HomeFooter/> 
            </div>
        </WidthLimiter>
    )
}

export default HomeLayout