import React from 'react'
import { AxiosResponse } from 'axios'


import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setModalVisiblity, setModalDone } from '../../../features/systemSlice'
import {ReactComponent as Close} from '../../../assets/icons/close1.svg'
import InputWithLabel from '../inputWithLabel'
import axiosInstance from '../../../utils/axios'
import { CoreValidator,IsEmailValidator } from '../../../utils/validators'
import { setComponentMessage, unsetComponentMessagesWithSignature } from '../../../features/messages/componentMessageSlice'
import { useComponentMessage } from '../../../hooks/componentMessage'
import { userRegister } from '../../../features/users/userSlice'
import {ReactComponent as Waiting} from '../../../assets/icons/spinner_white.svg'
import {ComponentMessage} from '../..'

import { AddTeacherByAdmin } from '../../../types/slices/users'
import { MessageType } from '../../../types/slices/messages'
import { ModalDoneActionTypeEnum } from '../../../types/slices/system'

import './addTeacherModal.css'

const COMP_SIGNATURE = 'addTeacherForm'

const AddTeacherModal = () => {
    const dispatch = useAppDispatch()
    const [value,setValue] = React.useState<AddTeacherByAdmin>({email:'', password:'',username:'',categories:[]})
    const axiosInstace = axiosInstance(false)
    const [categories, setCategories] = React.useState<string[]>([])
    const [numOfSelectionField, setNumOfSelectionField] = React.useState<number>(1)
    const [arrayOfSelection, setArrayOfSelection] = React.useState<number[]>([])
    const {isLoading} = useAppSelector((state) => state.user)
    const { registrationSuccess } = useAppSelector((state) => state.system) 

    const emailMessage = useComponentMessage(COMP_SIGNATURE,'email')
    const passwordMessage = useComponentMessage(COMP_SIGNATURE,'password')
    const generalMessage = useComponentMessage(COMP_SIGNATURE,'general')

    const isEmail = new IsEmailValidator(COMP_SIGNATURE,'email')
    const emailValidator = new CoreValidator(isEmail,dispatch)

    const onCancelClick = () => {
        dispatch(dispatch(setModalVisiblity({visibility:false, component: ''})))
    }

    const changeHandler = (e: any) => {
        if (e.currentTarget['name'] === 'categories'){
            const index = e.currentTarget.getAttribute("data-index")
            let categoriesValue = value.categories
            if (categoriesValue.length === parseInt(index)){
                categoriesValue[index - 1] = e.currentTarget.value
                setValue({...value,'categories': categoriesValue})
            }
            else{
                categoriesValue.push(e.currentTarget.value)
                setValue({...value,'categories': categoriesValue})
            }
        }
        else{
            setValue({...value,[e.currentTarget.name]:e.currentTarget.value})
        }
    }
    const categoryRequest = async () => {
        try{
            const result = await axiosInstace.get('/category') as AxiosResponse
            setCategories(result.data)
            // setValue({...value, categories: result.data[0]})
        }
        catch(err){
            setComponentMessage({
                content: 'Categories can not fetch',
                signature: COMP_SIGNATURE,
                context: 'general',
                type: MessageType.fail   
            })
        }
    }
    const onSubmitClick = () => {
        if (isSubmitButtonDisabled){
            return;
        }
        dispatch(unsetComponentMessagesWithSignature(COMP_SIGNATURE))
        const validationResult1 = emailValidator.start(value.email)
        if (validationResult1){
            dispatch(userRegister({value:value,registerByAdmin:true}))
        }
    }
    React.useEffect(() => {
        categoryRequest()
    },[])
    React.useEffect(() => {
        setArrayOfSelection(prevVal => [...prevVal,numOfSelectionField])
    },[numOfSelectionField])
    const onMoreFieldClick = () => {
        if (numOfSelectionField <= 1){
            setNumOfSelectionField(numOfSelectionField + 1)
        }
    }
    React.useEffect(() => {
        if (registrationSuccess){
            dispatch(setModalDone(ModalDoneActionTypeEnum.teacherAdded))
        }
    },[registrationSuccess])
    const isSubmitButtonDisabled = value.password.length === 0 || value.email.length === 0 || value.username.length === 0
    return (
        <div className='addTeacherModalContainer'>
            <div className='addTeacherModalTitle'>
                <span>Add Teacher</span>
            </div>
            <Close 
                className='addTeacherModalCloseButton' 
                onClick={onCancelClick}
                width={20}
                height={20}    
            />
            <div>
                <InputWithLabel
                    isRequired= {true}
                    title= 'Username'
                    type= 'text'
                    errorMessage= {[]}
                    value= {value.username}
                    changeHandler= {changeHandler}
                    labelColor = "#F6F6F6"
                    fieldName='username'
                />
                <InputWithLabel
                    isRequired= {true}
                    title= 'Password'
                    type= 'password'
                    errorMessage= {passwordMessage}
                    value= {value.password}
                    labelColor = "#F6F6F6"
                    changeHandler= {changeHandler}
                    fieldName='password'
                />
                <InputWithLabel
                    isRequired= {true}
                    title= 'Email'
                    type= 'email'
                    errorMessage= {emailMessage}
                    value= {value.email}
                    labelColor = "#F6F6F6"
                    changeHandler= {changeHandler}
                    fieldName='email'
                />
                {arrayOfSelection.map((item) => {
                    return (
                        <SelectionElement
                            changeHandler={changeHandler}
                            categories = {categories}
                            value = {value.categories[item-1]}
                            key={item}
                            index={item}
                        />
                    )
                })}
                <span className='clickableText' onClick={onMoreFieldClick}>Add more field</span>
                <div className='primaryButton createAccountButton' onClick={onSubmitClick} data-disabled={isSubmitButtonDisabled}>
                    <span className='buttonTextContainer'>Create account {isLoading ?<Waiting style={{width:50,height:50}}/>:null}</span>
                </div>
                {generalMessage ? <ComponentMessage messages={generalMessage}/> : null}
            </div>
        </div>
    )
}
type SelectionelementType = {
    changeHandler: any,
    categories: string[],
    value: string,
    index: number
}
const SelectionElement = ({changeHandler,categories,value,index}:SelectionelementType) => {
    return (
        <div className="input-group">
            <select name="categories" data-index={index} onChange={changeHandler} id="categories" className='user-input' value={value}>
                {categories?.map((element) => {
                    return <option key={element} value={element}>{element}</option>
                })}
            </select>
            <label htmlFor="user" className="user-label">Field<span style={{color:'red'}}>*</span></label>
        </div>
    )
}
export default AddTeacherModal
