import axios from "axios";

import { BASE_URL } from "../constant/server_address";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const getAxiosInstance = (authorize: boolean = true) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  if (authorize) {
    axios.defaults.headers.common = headers;
  }
  axios.defaults.baseURL = BASE_URL;
  return axios;
};
export default getAxiosInstance;
