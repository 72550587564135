import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetInnerDim } from '../../hooks/dims'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useComponentMessage } from '../../hooks/componentMessage'
import { userLogin, resetPassword, setSniperLink } from '../../features/users/userSlice'
import { CoreValidator, IsEmailValidator, RequiredValidator } from '../../utils/validators'
import { unsetComponentMessagesWithSignature } from '../../features/messages/componentMessageSlice'
import { ComponentMessage, WidthLimiter, AuthenticationCover, InputWithLabel } from '../../components'

import { ReactComponent as Close } from '../../assets/icons/close1.svg'
import { ReactComponent as EmailDeliver } from '../../assets/icons/emailDeliver.svg'
import { ReactComponent as ForgetPassword } from '../../assets/icons/forgetPassword.svg'

import { UserLoginType } from '../../types/slices/users'

import './login.css'

const COMP_SIGNATURE = 'loginFormMessages'
const TEXT = 'Your registration will be checked by our team and we contact to you with Email'

const LoginComponent: React.FC = () => {
    const { height } = useGetInnerDim()
    const [isRemember, setIsRemeber] = React.useState(true)
    const [modalActive, setModalActive] = React.useState(false)
    const [value, setValue] = React.useState<UserLoginType>({ email: '', password: '' })
    const [forgetEmail, setForgetEmail] = React.useState<string>('')

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isLoading, sniperLink } = useAppSelector((state) => state.user)

    const emailMessage = useComponentMessage(COMP_SIGNATURE, 'email')
    const passwordMessage = useComponentMessage(COMP_SIGNATURE, 'password')
    const generalMessage = useComponentMessage(COMP_SIGNATURE, 'generalErrors')

    const isEmail = new IsEmailValidator(COMP_SIGNATURE, 'email')
    const isRequired = new RequiredValidator(COMP_SIGNATURE, 'password')

    const EmailValidator = new CoreValidator(isEmail, dispatch)
    const passwordValidator = new CoreValidator(isRequired, dispatch)

    const submitHandler = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        dispatch(unsetComponentMessagesWithSignature(COMP_SIGNATURE))
        const validator1Res = EmailValidator.start(value.email)
        const validator2Res = passwordValidator.start(value.password)
        if (validator1Res && validator2Res) {
            dispatch(userLogin({ input: value, isRemember }))
        }
    }
    const changeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        setValue({ ...value, [e.currentTarget.name]: e.currentTarget.value })
    }
    const onRemember = () => {
        setIsRemeber(!isRemember)
    }
    const onForgetPassClick = () => {
        dispatch(resetPassword(forgetEmail))
    }
    const onForgetPassOpenClick = () => {
        setModalActive(true)
    }
    const onForgetPassCloseClick = () => {
        setModalActive(false)
        dispatch(setSniperLink(null))
    }
    const onCloseClick = () => {
        navigate('/')
    }
    const onForgetEmailChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        setForgetEmail(e.currentTarget.value)
    }
    const onEmailCheckClick = () => {
        if (sniperLink !== null) {
            window.open(sniperLink, '_blank')
            setModalActive(false)
            dispatch(setSniperLink(null))
        }
    }
    return (
        <div className='authPagesMainContainer' style={{ height: height }}>
            {/* Forget Password modal */}
            {/* This modal uses some style of modal component */}
            <div className='modalContainer' style={{ display: modalActive ? 'flex' : 'none' }}>
                <div className='forgetPassModalContainer'>
                    <Close className='forgetPasswordClose' onClick={onForgetPassCloseClick} />
                    {sniperLink !== null
                        ?
                        (
                            <>
                                <EmailDeliver className='forgetPasswordBanner' />
                                <span style={{ fontWeight: 700, fontSize: 20, marginTop: 24 }}>An email was sent to your email address. Please follow the instruction in sent email.</span>
                                <div className='primaryButton' onClick={onEmailCheckClick} style={{ width: '100%', height: '5vh', marginTop: 40 }}>Check email</div>
                            </>
                        )
                        :
                        (
                            <>
                                <ForgetPassword className='forgetPasswordBanner' />
                                <div style={{ fontSize: 12 }}>
                                    <a href="https://www.freepik.com/free-vector/tiny-people-carrying-key-open-padlock_13683703.htm">Image by pch.vector</a> on Freepik
                                </div>
                                <div className='forgetPasswordBodyContainer'>
                                    <span className='forgetPasswordTitle'>Forget your password?</span>
                                    <span style={{ marginTop: 20 }}>Please enter the email you registered with!</span>
                                    <InputWithLabel
                                        changeHandler={onForgetEmailChangeHandler}
                                        value={forgetEmail}
                                        title="Email"
                                        isRequired={true}
                                        type='email'
                                        errorMessage={passwordMessage}
                                    />
                                    <div className='primaryButton submitButton' onClick={onForgetPassClick} style={{ marginTop: 20 }}>Send</div>
                                    {/* <span>Don’t have an account yet ? <Link to='/auth/register'>Register now</Link></span> */}
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
            <AuthenticationCover text={TEXT} />
            <div className='loginFormMainContainer' style={{ height: height }}>
                <span className='loginFormTitle'>
                    Login <Close className='userAuthCloseButton' onClick={onCloseClick} />
                </span>
                <div style={{ marginBottom: 20 }}>
                    {generalMessage ? <ComponentMessage messages={generalMessage} /> : null}
                </div>

                <InputWithLabel
                    changeHandler={changeHandler}
                    value={value.email}
                    title="email"
                    isRequired={true}
                    type='email'
                    errorMessage={emailMessage}
                />
                <InputWithLabel
                    changeHandler={changeHandler}
                    value={value.password}
                    title="password"
                    isRequired={true}
                    type='password'
                    errorMessage={passwordMessage}
                />
                <div className="rememberContainer">
                    <input type='checkbox' name='remember' value='remember' checked={isRemember} onChange={onRemember} />
                    <span>Remember me!</span>
                </div>
                <input type='submit' onClick={submitHandler} className='loginFormSubmitButton' value={isLoading ? 'Please wait... ' : 'login'} disabled={value.email.length == 0 || value.password.length == 0 || isLoading} />
                <span><span style={{ color: 'red' }}>*</span> This login page is only for registered teachers. For now, teachers' registration is not available, and will be coming soon</span><br />
                {/* <span className='loginFormLastText'>Don't have an account yet? <Link className='loginFormLastTextLink' to='/auth/register'>Register now</Link></span> */}
                <span className='loginFormLastText'>Forget password? <span className='loginFormLastTextLink' onClick={onForgetPassOpenClick}>Click here</span></span>
            </div>
        </div>
    )
}

const LoginPage = () => {
    return (
        <WidthLimiter>
            <LoginComponent />
        </WidthLimiter>
    )
}

export default LoginPage
