import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { homeSideBarVisibility } from '../../../../features/systemSlice'
import {ReactComponent as Line1} from '../../../../assets/icons/line1.svg'
import {ReactComponent as Line2} from '../../../../assets/icons/line2.svg'
import {ReactComponent as Line3} from '../../../../assets/icons/line3.svg'
import {ReactComponent as Logo} from '../../../../assets/logo/logo_white.svg'
import {ReactComponent as Dashboard} from '../../../../assets/icons/dashboard_white.svg'
import {ReactComponent as Registration} from '../../../../assets/icons/registration.svg'
import {ReactComponent as Login} from '../../../../assets/icons/login.svg'
import {ReactComponent as Home} from '../../../../assets/icons/home.svg'
import {ReactComponent as Articles} from '../../../../assets/icons/articles.svg'
import {ReactComponent as Logout} from '../../../../assets/icons/logout_white.svg'

import { logout } from '../../../../features/users/userSlice'

import './styles.css'

type props = {
    show: boolean
}
const HomeSideBar = (props:props) => {
    const {show} = props
    const ref = React.useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {isAuthenticated} = useAppSelector((state) => state.user)
    const outsideClickHandler = (e:any) => {
        if (!ref?.current?.contains(e.target)){
            if (e.target.getAttribute("name") !== 'menuButton'){
                dispatch(homeSideBarVisibility(false))
            }
        }
    }
    React.useEffect(() => {
        document.addEventListener('click',outsideClickHandler)
        return () => {
            document.removeEventListener('click',outsideClickHandler)
        }
    },[])
    const onNavigateClick = (url:string) => {
        navigate(url)
        dispatch(homeSideBarVisibility(false))
        return undefined;
    }
    const onLogoutClick = () => {
        dispatch(logout())
        return undefined;
    }
    return (
        <div  className='homeSideBarMainContainer' style={{display: show? 'flex': 'none'}}>
            <div ref={ref} className='homeSideBarSubContainer'>
                <Line1 />
                <Line2 style={{marginTop:'-40%'}} />
                <div className='homeSideBarTitleContainer'>
                    <Logo width={24} height={24} />Mathustad
                </div>
                <div className='homeSideBarMenuContainer'>
                    {isAuthenticated 
                    ? 
                        (   
                            <>
                                <div className='homeSideBarMenu' data-border='true' onClick={() => onNavigateClick('/dashboard')}>
                                    <Dashboard /> Dashboard
                                </div>
                                <div className='homeSideBarMenu' data-border='true' onClick={() => onNavigateClick('/')}>
                                    <Home/> Home
                                </div>
                                <div className='homeSideBarMenu'  onClick={() => onNavigateClick('/articles')}>
                                    <Articles/> Articles
                                </div>
                                <div className=' homeSideBarMenu homeSideBarMenuLogout' onClick={onLogoutClick}>
                                    <Logout/> Logout
                                </div>
                            </>
                        )
                    :   
                        (
                            <>
                                <div className='homeSideBarMenu' data-border='true' onClick={() => onNavigateClick('/')}>
                                    <Home/> Home
                                </div>
                                <div className='homeSideBarMenu' data-border='true' onClick={() => onNavigateClick('/articles')}>
                                    <Articles/> Articles
                                </div>
                                <div className='homeSideBarMenu' data-border='true' onClick={() => onNavigateClick('/auth/register')}>
                                    <Registration/> Register
                                </div>
                                <div className='homeSideBarMenu'  onClick={() => onNavigateClick('/auth/login')}>
                                    <Login/> Login
                                </div>
                            </>
                        )
                    }
                    <Line3/>
                </div>
            </div>
        </div>
    )
}
export default HomeSideBar