import React from 'react'
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom'
import imageCompression from 'browser-image-compression';


import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { setMessage } from '../../features/messages/messageSlice'
import { DashboardOutletContextType } from '../../types/dashboard'
import { ImageDisplayer, ComponentMessage } from '../../components'
import { MessageType } from '../../types/slices/messages';
import { useComponentMessage } from '../../hooks/componentMessage';
import { changeImage, userEdit, imageUpload, profileEditChangeState } from '../../features/users/userSlice';
import { setComponentMessage, unsetComponentMessagesWithSignature } from '../../features/messages/componentMessageSlice';


import { ReactComponent as EditImage } from '../../assets/icons/editImage.svg'

import './profileEdit.css'

const COMP_SIGNATURE = 'teacherProfileEdit'

const ProfileEdit = () => {
    const { mar } = useOutletContext<DashboardOutletContextType>()
    const [image, setImage] = React.useState<any>(null)
    const [value, setValue] = React.useState({ username: '', password: '', rePassword: '' })
    const user = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const fileInputRef = React.useRef<HTMLInputElement>(null)
    const passwordMessage = useComponentMessage(COMP_SIGNATURE, 'password')
    const changeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        setValue({ ...value, [e.currentTarget.name]: e.currentTarget.value })
    }
    const inputBoxesHandler = () => {
        let changedValues: any = {}
        let forbidSend: boolean = false
        // Check for password changes
        if (value.password.length > 0) {
            if (value.password !== value.rePassword) {
                forbidSend = true
                dispatch(setComponentMessage({
                    content: "Password values do not match",
                    signature: COMP_SIGNATURE,
                    context: 'password',
                    type: MessageType.fail
                }))
            }
            else {
                changedValues.password = value.password
            }
        }
        //check for username changes
        if (value.username.length > 0 && !forbidSend) {
            changedValues.username = value.username
        }
        if (Object.keys(changedValues).length > 0) {
            dispatch(userEdit(changedValues))
        }
    }
    const onClickHandle = (e: any) => {
        dispatch(unsetComponentMessagesWithSignature(COMP_SIGNATURE))
        inputBoxesHandler()
        e.preventDefault()
        if (image) {
            const formData = new FormData()
            if (image) {
                formData.append('file', image)
                dispatch(imageUpload(formData))
            }
        }
    }
    const onUploadImage = async (e: any) => {
        const splitedPath = e.target.value.split('.')
        if (['jpeg', 'png', 'jpg'].includes(splitedPath[splitedPath.length - 1])) {
            const options = {
                maxSizeMB: .2,
                maxWidthOrHeight: 200,
                useWebWorker: true,
            }
            const compressedFile = await imageCompression(e.target.files[0], options);
            setImage(compressedFile)
            let buffer = await compressedFile.arrayBuffer()
            const src = (Buffer.from(buffer).toString('base64'))
            if (src) {
                dispatch(changeImage(src))
            }
        }
        else {
            dispatch(setMessage({
                content: { message: 'Unsupported format' },
                type: MessageType.fail,
                protected: false
            }))
        }
    }
    React.useEffect(() => {
        if (user.profileEditSuccess) {
            dispatch(profileEditChangeState(false))
            navigate('/')
        }
    }, [user.profileEditSuccess])
    const submitButtonDisable =
        (user.isLoading || user.imageUploading) ||
        (value.password.length === 0 && value.username.length === 0 && !image) ||
        (value.password.length > 0 && value.rePassword.length === 0)

    const buttonValue = user.isLoading || user.imageUploading ? 'Wait' : 'Update'
    return (
        <div style={{ marginLeft: mar, height: '100%', marginTop: '1%' }} className='teacherProfileContainer'>
            <div className='teacherProfileSections'>
                <div className='teacherProfileEditFormContainer'>
                    <div className='teacherProfileImageEditContainer'>
                        <ImageDisplayer imgDim={150} />
                        <label className='imageUploader'>
                            <EditImage />
                            <input type='file' onChange={onUploadImage} ref={fileInputRef} accept="image/png, image/jpeg" />
                        </label>
                    </div>
                    <div className="input-group">
                        {/* {emailMessage ? <ComponentMessage messages={emailMessage} /> : null} */}
                        <input type="text" name="username" className='user-input' onChange={changeHandler} placeholder={user.info.username} />
                        <label style={{ transform: value.username.length ? 'translate(0px, 0px)' : '' }} className="user-label">Username</label>
                    </div>
                    <div className="input-group">
                        {passwordMessage ? <ComponentMessage messages={passwordMessage} /> : null}
                        <input type="password" name="password" className={`user-input ${passwordMessage.length > 0 ? 'error' : ''}`} onChange={changeHandler} />
                        <label style={{ transform: value.password.length ? 'translate(0px, 0px)' : '' }} className="user-label">Password</label>
                    </div>
                    <div className="input-group">
                        {/* {passwordMessage ? <ComponentMessage messages={passwordMessage} /> : null} */}
                        <input type="password" name="rePassword" className={`user-input ${passwordMessage.length > 0 ? 'error' : ''}`} onChange={changeHandler} />
                        <label style={{ transform: value.rePassword.length ? 'translate(0px, 0px)' : '' }} className="user-label">Password</label>
                    </div>
                    <input type='submit' disabled={submitButtonDisable} value={buttonValue} onClick={onClickHandle} className='primaryButton teacherProfileSubmit' />
                </div>
            </div>
        </div>
    )
}
export default ProfileEdit