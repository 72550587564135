import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getAllArticles } from '../../features/article/userArticleSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ArticleCardSkeleton, ArticleListCard, HomeHeader } from '../../components'


import './articles.css'

const Articles = () => {
    const [searchKey, setSearchKey] = React.useState<string>('')
    const dispatch = useAppDispatch()
    const { AllArticles, currentPage, articleLoading } = useAppSelector((state) => state.userArticle)
    const navigate = useNavigate()
    React.useEffect(() => {
        // dispatch(getAllArticles({}))
        window.scrollTo(0, 0)
    }, [])
    const showMoreClickHandler = () => {
        if (!articleLoading) {
            dispatch(getAllArticles({ page: currentPage + 1, searchKey: searchKey }))
        }
    }
    const onSearchClick = () => {
        dispatch(getAllArticles({ page: 0, searchKey: searchKey }))
    }
    const onSearchBoxChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchKey(e.currentTarget.value)
    }
    return (
        <>
            <HomeHeader backgroundColor='white' />
            <div className='articlesPageMainContainer'>
                <div className='homePageSearchBoxContainer'>
                    <input type='text' className='homePageSearchBoxInput' placeholder='Search article' onChange={onSearchBoxChange} />
                    <button className='primaryButton homePageSearchButton' onClick={onSearchClick}>
                        <span style={{ fontWeight: 700, fontSize: 16 }}>Search</span>
                    </button>
                </div>
                <div className='articlesPageArticleContainer'>
                    {AllArticles.map((item: any) => {
                        return (
                            <ArticleListCard item={item} navigate={navigate} key={item._id} />
                        )
                    })}
                    {articleLoading ? <ArticleCardSkeleton count={1} /> : null}
                </div>
                <div data-disabled={articleLoading} className='primaryButton showMoreButton' onClick={showMoreClickHandler}>Show more</div>
            </div>
        </>
    )
}
export default Articles