import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from '../../../../assets/logo/logo_white.svg'
import { monitorType } from '../../../../constant/dims'
import { useGetDeviceType } from '../../../../hooks/dims'

import './style.css'

const HomeFooter = () => {
    const deviceType = useGetDeviceType()
    if (deviceType === monitorType.LargeDesktop || deviceType === monitorType.Desktop || deviceType === monitorType.Tablet)
        return <MobileExceptLayout />
    else if (deviceType === monitorType.Mobile)
        return <MobileLayout />
    return <div></div>
}
const MobileExceptLayout = () => {
    return (
        <div className='homeFooterMainContainer'>
            <div className='homeFooterSubContainer'>
                <div className='homeFooterTitle'>
                    <div className='homeFooterlogoContainer'>
                        <Logo className='homeFooterIcons' />
                        <span className='homeFooterGeneralText'>Mathustad</span>
                    </div>
                    <span className='homeFooterGeneralText'>
                        Is a website for increasing your knowladge
                    </span>
                </div>
                <div className='homeFooterContentContainer'>
                    <div className='homeFooterContentSubContainer'>
                        <span className='homeFooterGeneralText'>
                            Email : <a href="mailto: Mathustad.team@gmail.com" style={{ color: 'white' }}>Mathustad.team@gmail.com</a>
                        </span>
                        <div className='homeFooterContentIconContainer'>
                            <Link to='#'><img src={require('../../../../assets/icons/twitter.png')} /></Link>
                            <Link to='#'><img src={require('../../../../assets/icons/linkedIn.png')} /></Link>
                            <Link to='#'><img src={require('../../../../assets/icons/facebook.png')} /></Link>
                        </div>
                    </div>
                    <div className='seperatorLine'></div>
                    <div className='homeFooterContentSubContainer'>
                        <span className='homeFooterGeneralText'>© Mathustad. All rights reserved.</span>
                        <div className='homeFooterContentIconContainer'>
                            <span className='homeFooterGeneralText'>Terms</span>
                            <span className='homeFooterGeneralText'>Privacy</span>
                            <span className='homeFooterGeneralText'>Cookies</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const MobileLayout = () => {
    return (
        <div className='homeFooterMainContainer'>
            <div className='homeFooterSubContainer'>
                <div className='homeFooterRow1'>
                    <div className='homeFooterContentIconContainer1'>
                        <span className='homeFooterGeneralText'>Terms</span>
                        <span className='homeFooterGeneralText'>Privacy</span>
                        <span className='homeFooterGeneralText'>Cookies</span>
                    </div>
                    <div className='homeFooterRightPanelMobile'>
                        <div className='homeFooterContentIconContainer2'>
                            <Link to='#'><img src={require('../../../../assets/icons/twitter.png')} className="homeFooterSocialMediaIcon" /></Link>
                            <Link to='#'><img src={require('../../../../assets/icons/linkedIn.png')} className="homeFooterSocialMediaIcon" /></Link>
                            <Link to='#'><img src={require('../../../../assets/icons/facebook.png')} className="homeFooterSocialMediaIcon" /></Link>
                        </div>
                        <div>
                            Email : <a href="mailto: Mathustad.team@gmail.com" style={{ color: 'white' }}>Mathustad.team@gmail.com</a>
                        </div>
                    </div>
                </div>
                <div className='seperatorLine'></div>
                <div className='homeFooterRow2'>
                    <span className='homeFooterGeneralText'>© Mathustad. All rights reserved.</span>
                </div>
            </div>
        </div>
    )
}

export default HomeFooter