import React from 'react'
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom'
import uuid4 from "uuid4";

import { TextEditor } from '../../components'
import { MathJax } from '../../components'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { createArticle, articleUpdate } from '../../features/article/teacherArticleSlice'
import { useComponentMessage } from '../../hooks/componentMessage';
import { ComponentMessage } from '../../components'
import { CoreValidator, RequiredValidator } from '../../utils/validators';
import { unsetComponentMessagesWithSignature } from '../../features/messages/componentMessageSlice';
import { setCreateArticle, setUpdateArticle } from '../../features/article/teacherArticleSlice';
import { articleImageList } from '../../utils/article'
import { ReactComponent as Waiting } from "../../assets/icons/spinner_white.svg"
import { MessageType } from '../../types/slices/messages';
import { useGetOuterHeight } from '../../hooks/dims';

import { DashboardOutletContextType } from '../../types/dashboard'
import { ComponentMessageTypeBase } from '../../types/slices/messages';

import './index.css'
import { setMessage } from '../../features/messages/messageSlice';

const COMP_SIGNATURE = 'publishFormMessages'

const Dashboard = () => {
    const { mar } = useOutletContext<DashboardOutletContextType>()
    const user = useAppSelector((state) => state.user)
    const teacherArticle = useAppSelector((state) => state.teacherArticle)
    const categories = user.info.categories as any
    const { height } = useGetOuterHeight()
    let stateId: string // Id of article that navigate to this page
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    let selectedArticle: any = {}
    const [value, setValue] = React.useState<string>('')
    const [title, setTitle] = React.useState<string>('')
    const [hashtag, setHashtag] = React.useState<string>('')
    const [hashtags, setHashtags] = React.useState<{}[]>([])
    const [selectCategory, setSelectCategory] = React.useState(categories.length > 0 ? categories[0].name : '')
    const [imageUpload, setImageUpload] = React.useState<boolean>(false)
    const [summary, setSummary] = React.useState<string>('')

    const quillRef: any = React.useRef<HTMLElement>(null);
    const imageList = React.useRef<string[]>([])

    const titleIsRequired = new RequiredValidator(COMP_SIGNATURE, 'title')
    const contentIsRequired = new RequiredValidator(COMP_SIGNATURE, 'content', 'Article can\'t be empty')

    const titleValidator = new CoreValidator(titleIsRequired, dispatch)
    const contentValidator = new CoreValidator(contentIsRequired, dispatch)

    const onTitleUpdate = (e: React.FormEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value)
    }
    const onHashtagUpdate = (e: React.FormEvent<HTMLInputElement>) => {
        setHashtag(e.currentTarget.value)
    }
    const onHashtagClick = () => {
        if (hashtag && hashtag.length > 0) {
            const tempElement = { name: hashtag, id: uuid4() }
            setHashtags([...hashtags, tempElement])
            setHashtag('')
        }
    }
    const onSummaryUpdate = (e: any) => {
        setSummary(e.currentTarget.value)
    }
    const onDeleteHashtag = (id: number) => {
        setHashtags(oldValues => {
            return oldValues.filter((hashtag: any) => hashtag.id !== id)
        })
    }
    const onSave = async (saveAs: string) => {
        if (!teacherArticle.loading) {
            dispatch(unsetComponentMessagesWithSignature(COMP_SIGNATURE))
            const validationRes1 = titleValidator.start(title)
            const validationRes2 = contentValidator.start(value)
            if (validationRes1 && validationRes2) {
                let isPublished: boolean = saveAs == 'publish' ? true : false
                const articleBody = quillRef.current.editor.root
                const clonedArticleBody = articleBody.cloneNode(true)
                imageList.current = []
                if (location?.state?.id) {
                    articleImageList(clonedArticleBody, imageList)
                    dispatch(articleUpdate({ id: location?.state?.id, value: { isPublished: isPublished, content: value, title: title, summary, hashtags: hashtags, category: selectCategory, imageArray: imageList.current } }))
                    return
                }
                // articleImagesOrganizer(clonedArticleBody)
                articleImageList(clonedArticleBody, imageList)
                dispatch(createArticle({ content: clonedArticleBody.innerHTML, title: title, summary: summary, hashtags: hashtags, isPublished, category: selectCategory, imageArray: imageList.current }))
            }
        }
    }
    const refValue = React.useRef<HTMLInputElement>(null)
    const onHashtagPressButton = (e: KeyboardEvent) => {
        if (e.key == 'Enter') {
            onHashtagClick()
        }
    }
    React.useEffect(() => {
        refValue.current?.addEventListener('keypress', onHashtagPressButton)
        return () => {
            refValue.current?.removeEventListener('keypress', onHashtagPressButton)
        }
    })
    React.useEffect(() => {
        if (teacherArticle.createArticle) {
            navigate("/", { replace: true });
            dispatch(setCreateArticle(false))
        }
    }, [teacherArticle.createArticle])
    React.useEffect(() => {
        if (teacherArticle.articleUpdated) {
            navigate("/", { replace: true });
            dispatch(setUpdateArticle(false))
        }
    }, [teacherArticle.articleUpdated])
    const messages = useComponentMessage(COMP_SIGNATURE, 'general')
    const titleMessage = useComponentMessage(COMP_SIGNATURE, 'title')
    const contentMessage = useComponentMessage(COMP_SIGNATURE, 'content')
    React.useEffect(() => {
        stateId = location?.state?.id // If navigate from Articles or drafts pages in order to edit article this value will populated
        if (stateId && stateId !== undefined) {
            selectedArticle = teacherArticle.content.filter((element: any) => {
                return element._id === stateId
            })
            if (selectedArticle.length) {
                setTitle(selectedArticle[0].title)
                setSummary(selectedArticle[0].summary)
                quillRef.current.editor.root.innerHTML = selectedArticle[0].content
                setSelectCategory(selectedArticle[0].category.name)
                selectedArticle[0].hashtags.map((element: any) => {
                    const tempElement = { name: element, id: uuid4() }
                    hashtags.push(tempElement)
                })
            }
        }
    }, [])
    let ArticleBodyisEmpty = true

    if (quillRef.current) {
        const inner = quillRef.current.editor.root
        const brList = (inner.querySelectorAll("br"))
        if ((brList.length === 1 && value.length === 11) || value.length === 0) {
            ArticleBodyisEmpty = true
        }
        else {
            ArticleBodyisEmpty = false
        }
    }
    const isEmptyAllRequiredField = summary.length === 0 || title.length === 0 || hashtags.length === 0 || ArticleBodyisEmpty;
    return (
        <div style={{ marginLeft: mar, height: '100%' }} className='publishMainContainer'>
            <div className={`snackbar`} data-visible={imageUpload}>Uploading image...</div>
            <div style={{ height: '100%', width: '70%' }}>
                <div className='publishInfoContainer'>
                    <Optioncomponent label='Field' elements={categories} categoryValue={selectCategory} setCategoryValue={setSelectCategory} />
                    <InputComponent value={title} label='Title' placeholder='Write main title here' onChange={onTitleUpdate} err={titleMessage} />
                    <InputComponent refValue={refValue} value={hashtag} label='Hashtags' placeholder='Write some hashtags for searching' onChange={onHashtagUpdate} />
                </div>
                <div className='hashtagListContainer'>
                    {hashtags.map((item: any) => {
                        return <span key={item.id} className='publishHashtagCard'>
                            {item.name}
                            <span
                                className='publishHashtagCardClose'
                                onClick={() => onDeleteHashtag(item.id)}
                            />
                        </span>
                    })}
                </div>
                {messages ? <ComponentMessage messages={messages} /> : null}
                {contentMessage ? <ComponentMessage messages={contentMessage} /> : null}
                <textarea
                    className='summaryTextArea'
                    placeholder='Your summary about the article goes here'
                    onChange={onSummaryUpdate}
                    value={summary}
                />
                <TextEditor
                    quillRef={quillRef}
                    onChange={setValue}
                    setImageUpload={setImageUpload}
                />
                <div className='publishBottomSection'>
                    <div data-disabled={isEmptyAllRequiredField} className={`primaryButton publishButton ${teacherArticle.loading || isEmptyAllRequiredField ? 'publishButtonDisable' : ''}`} onClick={() => onSave('publish')}>
                        <span className='buttonTextContainer'>Publish {teacherArticle.loading ? <Waiting style={{ width: 50, height: 50 }} /> : null}</span>
                    </div>
                    <div className={`secondaryButton draftButton ${teacherArticle.loading || isEmptyAllRequiredField ? 'publishButtonDisable' : ''}`} onClick={() => onSave('draft')}>
                        <span className='buttonTextContainer'>Draft</span>
                    </div>
                </div>
            </div>
            <div style={{ width: '30%', height: height, display: 'flex', flexDirection: 'column', boxSizing: 'border-box', paddingLeft: 30, justifyContent: 'flex-end' }}>
                <div className="smartphone">
                    <div className="smartphoneContent">
                        <MathJax element={value} />
                        <div className='phonePowerButton' />
                    </div>
                </div>
            </div>
        </div>
    )
}
type InputComponentProps = {
    label: string,
    placeholder: string,
    value: string,
    refValue?: any,
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void,
    err?: ComponentMessageTypeBase[]
}
const InputComponent = ({ label, value, placeholder, onChange, refValue, err }: InputComponentProps) => {
    return (
        <div className='publishInputComponent'>
            <label className='publishInputsLabel'>{label}</label>
            <input ref={refValue} value={value} onChange={onChange} placeholder={placeholder} className='publishInputs' />
            {err ? <ComponentMessage messages={err} /> : null}
        </div>
    )
}
type OptionComponentType = {
    label: string,
    elements: string[],
    setCategoryValue: React.Dispatch<React.SetStateAction<string>>,
    categoryValue: string
}
const Optioncomponent = ({ label, elements, categoryValue, setCategoryValue }: OptionComponentType) => {
    const onChangeandler = (e: any) => {
        setCategoryValue(e.currentTarget.value)
    }
    return (
        <div className='publishInputComponent selectComponent'>
            <label className='publishInputsLabel'>{label}</label>
            <select value={categoryValue} onChange={onChangeandler} className='publishSelectInput' name="categories" id="categories">
                {elements?.map((element: any) => {
                    return <option key={element.name} value={element.name}>{element.name}</option>
                })}
            </select>
        </div>
    )
}
export default Dashboard