import React from 'react'
import { NavigateFunction } from 'react-router-dom'

import {UserImageDisplayer} from '../../..'
import {getDate} from '../../../../utils/date'

type props = {
    navigate: NavigateFunction,
    item: any
}

const ArticleListCard = ({item,navigate}:props) => {
    const onClickCard = (id: string) => {
        navigate(`/article/${id}`)
    }
    let isDeletedUser: boolean = false
    if (item.user === null){
        isDeletedUser = true
    }
    return (
        <div className='teacherArticlesCardContainer' style={{cursor:'pointer'}} onClick={() => onClickCard(item._id)}>
            <section className='teacherArticleSections'>
                <div className='articleCartTopSectionLeft'>
                    <UserImageDisplayer imageUrl={isDeletedUser ? '' :item.user.image} imgDim={60} userId={isDeletedUser? '' :item.user.slug}/>
                    <div className='teacherArticleTopSectionLeftInfo'>
                        <span className='teacherArticleTitle'>{item.title}</span>
                        <span className='teacherArticleUsername'>{isDeletedUser ? 'Mathustad Teacher' :item.user.username}</span>
                    </div>
                </div>
                <div className='homeCategoryText'>
                    {item.category.name}
                </div>
            </section>
            <section className='teacherArticleSections'>
                <span className='teacherArticleSummary'>{item.summary}</span>
            </section>
            <section className='teacherArticleSections'>
                <span className='teacherArticleCreatedAt'>{getDate(item.createdAt)}</span>
            </section>
        </div>
    )
}
export default ArticleListCard