import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import getAxiosInstance from "../../utils/axios";
import {
  All_ARTICLE_LIST,
  SEARCH_ARTICLES,
  GET_SINGLE_ARTICLE_BY_ID,
} from "../../constant/server_address";

import { UserArticlesGetAllArticlePayloadType } from "../../types/slices/articles";

const axios = getAxiosInstance(false);

const initialState: any = {
  recentArticle: [],
  AllArticles: [],
  detailArticle: {},
  searchedItem: [],
  searchedKey: "",
  searchLoading: false,
  articleLoading: false,
  currentPage: 0,
};
// This is async functio for loading all articles based on pages
export const getAllArticles = createAsyncThunk(
  "userArticleSlice/fetch/all",
  async (value: UserArticlesGetAllArticlePayloadType, thunkAPI) => {
    try {
      const page = value.page || 0;
      const searchKey = value.searchKey || "";
      const isAdmin = value.isAdmin || false
      if (searchKey.length === 0) {
        if (page === 0) {
          // Admin article report always fall into this condition
          const result = await axios.post(All_ARTICLE_LIST, {
            page: 0,
            searchKey: "",
            isAdmin: isAdmin === true
          });
          thunkAPI.dispatch(
            userArticleSlice.actions.setAllarticlesAndRecent(result.data)
          );
          thunkAPI.dispatch(userArticleSlice.actions.setCurrentPage(0));
        } else {
          const result = await axios.post(All_ARTICLE_LIST, {
            page: page,
            searchKey: "",
          });
          if (result.data.length > 0) {
            thunkAPI.dispatch(
              userArticleSlice.actions.setAllArticles(result.data)
            );
            thunkAPI.dispatch(userArticleSlice.actions.setCurrentPage(page));
          }
        }
      } else {
        const result = await axios.post(All_ARTICLE_LIST, {
          page: page,
          searchKey: searchKey,
        });
        if (page === 0) {
          thunkAPI.dispatch(
            userArticleSlice.actions.setAllArticleInit(result.data)
          );
          thunkAPI.dispatch(userArticleSlice.actions.setCurrentPage(0));
        } else {
          if (result.data.length > 0) {
            thunkAPI.dispatch(userArticleSlice.actions.setCurrentPage(page));
            thunkAPI.dispatch(
              userArticleSlice.actions.setAllArticles(result.data)
            );
          }
        }
      }
      thunkAPI.dispatch(userArticleSlice.actions.setArticleLoading(false));
    } catch (err: unknown) {
      thunkAPI.dispatch(userArticleSlice.actions.setArticleLoading(false));
    }
  }
);
export const searchArticleByHashtag = createAsyncThunk(
  "userArticleSlice/search",
  async (value: string, thunkAPI) => {
    const result = await axios.post(SEARCH_ARTICLES, { hashtag: value });
    return result;
  }
);
export const getDetailArticle = createAsyncThunk(
  "userArticleSlice/detail",
  async (value: String, thunkAPI) => {
    try {
      thunkAPI.dispatch(userArticleSlice.actions.setArticleLoading(true))
      const states = thunkAPI.getState() as any;
      const article = states.userArticle.AllArticles.filter((item: any) => {
        return item._id === value;
      });
      if (article.length === 0) {
        const result = await axios.get(GET_SINGLE_ARTICLE_BY_ID + `/${value}`);
        thunkAPI.dispatch(
          userArticleSlice.actions.setArticleDetail([result?.data])
        );
        thunkAPI.dispatch(userArticleSlice.actions.setArticleLoading(false))
      } else {
        thunkAPI.dispatch(userArticleSlice.actions.setArticleDetail(article));
        thunkAPI.dispatch(userArticleSlice.actions.setArticleLoading(false))
      }
    } catch (err: unknown) {
      thunkAPI.dispatch(userArticleSlice.actions.setArticleLoading(false))

    }
  }
);

const userArticleSlice = createSlice({
  name: "userArticleSlice",
  initialState,
  reducers: {
    setArticleDetail(state, action) {
      state.detailArticle = action.payload;
    },
    setAllarticlesAndRecent(state, action) {
      state.AllArticles = action.payload;
      state.recentArticle = action.payload;
    },
    setSearchedKey(state, action) {
      state.searchedKey = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setAllArticles(state, action) {
      state.AllArticles.push(...action.payload);
    },
    setArticleLoading(state, action) {
      state.articleLoading = action.payload;
    },
    setAllArticleInit(state, action) {
      state.AllArticles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllArticles.pending, (state, action) => {
      state.articleLoading = true;
    });
    builder.addCase(searchArticleByHashtag.pending, (state, action) => {
      state.searchLoading = true;
    });
    builder.addCase(searchArticleByHashtag.fulfilled, (state, action) => {
      state.searchedItem = action.payload?.data.content;
      state.searchLoading = false;
    });
    builder.addCase(searchArticleByHashtag.rejected, (state, action) => {
      state.searchedItem = [];
      state.searchLoading = false;
    });
  },
});

export const { setSearchedKey } = userArticleSlice.actions;
export default userArticleSlice.reducer;
