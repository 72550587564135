import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'

import {useAppSelector} from '../app/hooks'


type props = {
    redirect_path?: string,
    children?: JSX.Element,
    reversed?: boolean
}

const AdminProtectedRoute = ({redirect_path,children,reversed=false}: props) => {
    const {isAdmin} = useAppSelector((state) => state.user.info)
    if (!redirect_path){
        redirect_path = '/dashboard'
    }
    if( !isAdmin ) {
        return <Navigate to={redirect_path} replace/>
    }
    else {
        return  children ? children : <Outlet/> 
    }
}

export default AdminProtectedRoute