import 'react-loading-skeleton/dist/skeleton.css'

import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { DashboardPages } from '../../../../types/slices/system'
import { ReactComponent as ArrowIcon1 } from "../../../../assets/icons/arrow-1.svg";


import './style.css'
import { colors } from '../../../../constant/colors'

interface DashboardHeaderType {
    mar: number;
}

const DashboardHeader = ({mar}:DashboardHeaderType) => {
    const {dashboardPath} = useAppSelector((state) => state.system)    
    return (
        <div className='dashboardHeaderContainer' style={{marginLeft:mar}}  >
            <div>
                <span style={{color: colors.placeHolder,fontSize:32,fontWeight:700}}>Dashboard</span> 
                <span style={{marginLeft:15,marginRight:15}}><ArrowIcon1 /></span>
                <span style={{fontSize:32,fontWeight:700}}>{DashboardPages[dashboardPath]}</span>
            </div>
        </div>
    )
}

export default DashboardHeader