import { responsiveDims } from "../constant/dims";
import { monitorType } from "../constant/dims";
export const useGetInnerDim = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return { width, height };
};
export const useGetOuterHeight = () => {
  const width = window.outerWidth;
  const height = window.outerHeight;
  return { width, height };
};
export const useGetDeviceType = ():monitorType => {
  const { width } = useGetOuterHeight();
  if (width <= responsiveDims.mobileMin) {
    return monitorType.Mobile;
  }
  if (width <= responsiveDims.tabletMin && width > responsiveDims.mobileMin) {
    return monitorType.Tablet;
  }
  if (width > responsiveDims.tabletMin && width <= responsiveDims.desktopMin) {
    return monitorType.Desktop;
  }
  if (width > responsiveDims.desktopMin) {
    return monitorType.LargeDesktop;
  }
  return monitorType.Desktop
};
export const useValueSelector = (DeskVal:any,TabVal:any, mobVal:any):any => {
  const deviceType = useGetDeviceType()
  if (deviceType === monitorType.Desktop || deviceType === monitorType.LargeDesktop){
    return DeskVal
  }
  if (deviceType === monitorType.Tablet){
    return TabVal
  }
  if (deviceType === monitorType.Mobile){
    return mobVal
  }
}
