// export const BASE_URL = "https://mathustad.org";
// export const BASE_URL = "https://mathustad.herokuapp.com/";
export const BASE_URL = "https://mathustad.org/";
export const IMAGE_LOCATION = "assets/"

export const LOGIN_PAGE = "users/login";
export const REGISTER_PAGE = "users/register";
export const REGISTER_BY_ADMIN_PAGE = "users/protectedRegister"
export const TEACHER_PROFILE_UPDATE = "users/update";
export const TEACHER_UPLAOD_IMAGE = "users/upload";
export const USER_FORGET_PASSWORD = "users/forget"

export const VALIDATE_TOKEN = "users/validateToken";
export const CREATE_ARTICLE = "article";
export const UPLOAD_ARTICLE_IMAGE = "article/addImage";
export const IMAGE_RETRIEVE = "/multimedia/images";

export const ARTICLE_CREATE = "/article/teacher/create"; //Post
export const TEACHER_ARTICLES_LIST = "/article/teacher/list";
export const All_ARTICLE_LIST = "/article/list";
export const TEACHER_DELETE_ARTICLE = "/article/teacher/delete";
export const TEACHER_UPDATE_ARTICLE = "/article/teacher/update";
export const GET_SINGLE_ARTICLE_BY_ID = "/article/detail";
export const SEARCH_ARTICLES = "/article/search";

// Start admin address
export const GET_ALL_TEACHER_LIST = "/admin/teachers/list"
export const DELETE_TEACHER = "/admin/teachers/delete"
export const GET_REGISTER_REQUEST = "/admin/teachers/getRegister"
export const APPROVE_TEACHER_REQUEST = "admin/teachers/approve"
export const DELETE_TEACHER_REQUEST = "admin/teachers/reject"
