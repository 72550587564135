import { createSlice, createAction, Action } from "@reduxjs/toolkit";
import uuid4 from "uuid4";

import {
  ComponentMessageType,
  ComponentMessagePayloadType,
  MessageType,
} from "../../types/slices/messages";

const initialState: ComponentMessageType = [];

export interface CustomPayloadAction<T> extends Action {
  payload: T;
}

const setComponentMessageFromNetReq = createAction(
  "Componentmessages/set",
  function prepare(content) {
    return {
      payload: content,
    };
  }
);

const userSlice = createSlice({
  name: "componentMessage",
  initialState,
  reducers: {
    setMessage(state, action) {
      state.push({
        id: uuid4(),
        type: action.payload.type,
        content: action.payload.content,
        signature: action.payload.signature,
        context: action.payload.context,
      });
    },
    unsetMessage(state, action) {
      return state.filter((message) => message.id != action.payload);
    },
    unsetAllMessages(state) {
      return state.filter((_) => {
        return null;
      });
    },
    unsetMessageWithSignature(state, action) {
      return state.filter((message) => message.signature != action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      "Componentmessages/set",
      (state, action: CustomPayloadAction<ComponentMessagePayloadType>) => {
        const { content, signature, context } = action.payload;
        let message = content.message;
        state.push({
          id: uuid4(),
          content: message,
          signature: signature,
          context: context,
          type: MessageType.fail,
        });
      }
    );
  },
});

export const {
  setMessage: setComponentMessage,
  unsetMessage: unsetComponentMessage,
  unsetAllMessages: unsetAllComponentMessages,
  unsetMessageWithSignature: unsetComponentMessagesWithSignature,
} = userSlice.actions;
export default userSlice.reducer;

export { setComponentMessageFromNetReq };
