import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { getAllArticles, searchArticleByHashtag, setSearchedKey } from '../../features/article/userArticleSlice'
import { useAppDispatch,useAppSelector } from '../../app/hooks'
import {ReactComponent as BlackSpinner} from '../../assets/icons/spinner_black.svg'
import { ArticleListCard, ArticleCardSkeleton } from '../../components'
import { useGetInnerDim } from '../../hooks/dims'
import {HomeHeader} from '../../components'

import './home.css'

const Home = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [imgHeight, setImgHeight] = React.useState(0)
    const [searchBoxValue, setSearchedBoxValue] = React.useState<string>('')
    const { recentArticle, searchedItem, searchedKey, searchLoading, articleLoading } = useAppSelector((state) => state.userArticle)
    const [searchResultHidden, setSearchResultHidden] = React.useState(true)
    let {width} = useGetInnerDim()
    React.useEffect(() => {
        dispatch(getAllArticles({}))
    },[])
    const onSearchClick = () => {
        dispatch(setSearchedKey(searchBoxValue))
        setSearchResultHidden(false)
        if (searchBoxValue && searchBoxValue.length > 0) dispatch(searchArticleByHashtag(searchBoxValue))
    }
    const container = {
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            delayChildren: 0.5
          }
        }
    }
    if (width > 1920){
        width = 1920
    }
    const sizeChangeHandler = () => {
        setImgHeight(width * 0.33)
    }
    React.useEffect(() => {
        if (searchBoxValue.length === 0){
            setSearchResultHidden(true)  
            dispatch(setSearchedKey(''))
        }
    },[searchBoxValue])
    React.useEffect(() => {
        if (searchedKey.length !== 0){
            setSearchedBoxValue(searchedKey)
            setSearchResultHidden(false)
        }
        window.addEventListener('resize',sizeChangeHandler)
        sizeChangeHandler()
        return () => {
            window.removeEventListener('resize',sizeChangeHandler)
        }
    },[])
    const onSearchBoxValueChanged = (e:any) => {
        setSearchedBoxValue(e.currentTarget.value)
    }
    return(
            <>
                <HomeHeader backgroundColor='#AB77FD' color='white'/>
                <div className='homeTopSectionContainer'>
                    <div className='homePageTopSectionContainer' style={{height:imgHeight}}>
                        <span className='homePageTopSectionTitle'><span style={{color:'white'}}>Mathustad</span> is a website that provides Education content </span>
                        <div className='homePageSearchSection'>
                            <div className='homePageSearchBoxContainer'>
                                <input onChange={onSearchBoxValueChanged} value={searchBoxValue} type='text' className='homePageSearchBoxInput' placeholder='Search article'/>
                                <button className='primaryButton homePageSearchButton' onClick={onSearchClick}>
                                    <span style={{fontWeight: 700,fontSize: 16}}>Search</span>
                                </button>
                            </div>
                            <motion.div className='searchResultBox' variants={container} initial={searchResultHidden ? 'hidden': 'show'} animate={searchResultHidden ? 'hidden': 'show'} style={{display:searchResultHidden ?'None' :'flex'}}>
                                <div className='searchResultContainer'>
                                    {searchLoading 
                                    ? <BlackSpinner width={50} height={50}/>
                                    :
                                        (
                                            searchedItem.length ===0 ? 'Not found:(' :null
                                        )
                                    }
                                {searchedItem.map((item:any) => {
                                        return <SearchedArticleList item={item} key={item._id} />
                                    })}
                                </div>
                            </motion.div>
                        </div>
                    </div>
                    <div className='homePageArticleContainer'>
                        <span className='recentText'>Recent Articles</span>
                        {articleLoading ?<ArticleCardSkeleton count={2} /> : null}
                        {recentArticle.map((item:any) => {
                            return (
                                <ArticleListCard item={item} key={item._id} navigate={navigate} />
                            )
                        })}
                    </div>
                    <span className='primaryButton moreArticleButton' onClick={() => navigate('/articles')}>More Articles</span>
                </div>
            </>
    )
}
const SearchedArticleList = ({item}:any) => {
    const navigate = useNavigate()
    const onclickHandler = () => {
        navigate(`article/${item._id}`)
    }   
    return (
        <div className='SearchedItemContainer' onClick={onclickHandler}>
            <span className='searchedItemUsername'>{item.user.username}</span>
            <span className='searchedItemTitle'>{item.title}</span>
        </div>
    )
}
export default Home