import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import getAxiosInstance from "../../utils/axios";
import { GET_ALL_TEACHER_LIST, DELETE_TEACHER, GET_REGISTER_REQUEST, APPROVE_TEACHER_REQUEST, DELETE_TEACHER_REQUEST } from "../../constant/server_address";

import { AdminStateType } from "../../types/slices/users";
import { AxiosError, AxiosResponse } from "axios";
import { setMessage } from "../messages/messageSlice";
import { MessageType } from "../../types/slices/messages";

const initialState: AdminStateType = {
  teacherList: [],
  registerRequest: [],
  teacherListLoading: false,
  adminSliceWaiting: false,
};

const authAxios = getAxiosInstance(true);

export const getTeachersList = createAsyncThunk(
    "admin/teachers/fetch",
    async (value, thunkAPI) => {
        try {
            const result = await authAxios.get(GET_ALL_TEACHER_LIST) as AxiosResponse
            thunkAPI.dispatch(adminSlice.actions.getTeacherResult(result.data))
        } catch (err: unknown) {
            const error = err as AxiosError
            thunkAPI.dispatch(adminSlice.actions.getTeacherResult([]))
            return;
        }
    }
);
export const deleteTeacher = createAsyncThunk(
    "admin/teachers/delete",
    async (value:string, thunkAPI) => {
        authAxios.post(DELETE_TEACHER, {id:value})
    }
)
export const getRegisterRequest = createAsyncThunk(
    "admin/teachers/fetchRegister",
    async (value, thunkAPI) => {
        try{
            const result = await authAxios.get(GET_REGISTER_REQUEST)
            thunkAPI.dispatch(adminSlice.actions.getRegisterRequestResult(result.data))
        }
        catch(err:unknown){
            const error = err as AxiosError
            thunkAPI.dispatch(adminSlice.actions.getRegisterRequestResult([]))
        }
    }
)
export const approveTeacherRequest = createAsyncThunk(
    "admin/teachers/approve",
    async (value:string, thunkAPI) => {
        try{
            await authAxios.post(APPROVE_TEACHER_REQUEST,{id: value})
            thunkAPI.dispatch(adminSlice.actions.deleteFromRegisterRequestById(value))
            thunkAPI.dispatch(setMessage({
                content: {message:"User approved!"},
                type: MessageType.success,
                protected: true
            }))
            thunkAPI.dispatch(adminSlice.actions.setAdminSliceWaiting(false))
        }
        catch(err:unknown){
            const error = err as AxiosError
            thunkAPI.dispatch(setMessage({
                content: error.response?.data,
                type: MessageType.fail,
                protected: true
            }))
            thunkAPI.dispatch(adminSlice.actions.setAdminSliceWaiting(false))
        }
    }
)
export const rejectTeacherRequest = createAsyncThunk(
    "admin/teachers/reject",
    async (value:string, thunkAPI) => {
        try{
            await authAxios.post(DELETE_TEACHER_REQUEST, {id:value})
            thunkAPI.dispatch(adminSlice.actions.deleteFromRegisterRequestById(value))
            thunkAPI.dispatch(setMessage({
                content: {message:"User deleted!"},
                type: MessageType.success,
                protected: true
            }))
            thunkAPI.dispatch(adminSlice.actions.setAdminSliceWaiting(false))
        }
        catch(err:unknown){
            const error = err as AxiosError
            thunkAPI.dispatch(setMessage({
                content: error.response?.data,
                type: MessageType.fail,
                protected: true
            }))
            thunkAPI.dispatch(adminSlice.actions.setAdminSliceWaiting(false))
        }
    }
)
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getTeacherResult(state,action){
        state.teacherList = action.payload
        state.teacherListLoading = false
    },
    getRegisterRequestResult(state,action){
        state.teacherListLoading = false
        state.registerRequest = action.payload
    },
    deleteFromRegisterRequestById(state,action){
        state.registerRequest = state.registerRequest.filter((item:any) => {
            return item.slug !== action.payload
        }) as any
    },
    deleteFromTeachersListById(state,action){
        state.teacherList = state.teacherList.filter((item:any) => {
            return item.slug !== action.payload
        }) as any
    },
    setAdminSliceWaiting(state,action){
        state.adminSliceWaiting = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTeachersList.pending, (state,action) => {
        state.teacherListLoading = true
    })
    builder.addCase(getRegisterRequest.pending, (state,action) => {
        state.teacherListLoading = true
    })
    builder.addCase(rejectTeacherRequest.pending, (state,action) => {
        state.adminSliceWaiting = true
    })
  },
});

export const {  } =
adminSlice.actions;
export default adminSlice.reducer;
