import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DashboardOutletContextType } from '../../types/dashboard'
import { UserImageDisplayer } from '../../components'
import { getDate } from '../../utils/date'
import { getAllArticles } from '../../features/article/userArticleSlice'


import './articles.css'

const Articles = () => {
    const { mar } = useOutletContext<DashboardOutletContextType>()
    const dispatch = useAppDispatch()

    const { AllArticles } = useAppSelector((state) => state.userArticle)

    React.useEffect(() => {
        dispatch(getAllArticles({ isAdmin: true }))
    }, [])
    return (
        <div style={{ marginLeft: mar, height: '100%' }} className="adminArticlesContainer">
            <div className='adminArticlesGridStyle'>
                {AllArticles.map((item: any) => {
                    return <AdminArticles item={item} key={item._id} />
                })}
            </div>
        </div>
    )
}
// Reuse the styles of the ArticleCard component
type AdminArticlesProp = {
    item: any
}
const AdminArticles = ({ item }: AdminArticlesProp) => {
    const navigate = useNavigate()
    let isDeletedUser: boolean = false
    if (item.user === null) {
        isDeletedUser = true
    }
    const onClickCard = (id: string) => {
        navigate(`/article/${id}`)
        return undefined
    }
    return (
        <div className='teacherArticlesCardContainer' onClick={() => onClickCard(item._id)}>
            <section className='teacherArticleSections'>
                <div className='articleCartTopSectionLeft'>
                    <UserImageDisplayer imageUrl={isDeletedUser ? '' : item.user.image} imgDim={60} userId={isDeletedUser ? '' : item.user.slug} />
                    <div className='teacherArticleTopSectionLeftInfo'>
                        <span className='teacherArticleTitle'>{item.title}</span>
                        <span className='teacherArticleUsername'>{isDeletedUser ? 'Mathustad Teacher' : item.user.username}</span>
                    </div>
                </div>
                <div className='homeCategoryText'>
                    {item.category.name}
                </div>
            </section>
            <section className='teacherArticleSections'>
                <span className='teacherArticleSummary'>{item.summary}</span>
            </section>
            <section className='teacherArticleSections'>
                <span className='teacherArticleCreatedAt'>{getDate(item.createdAt)}</span>
            </section>
        </div>
    )
}
export default Articles

