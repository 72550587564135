import { createSlice, createAction, Action } from "@reduxjs/toolkit";
import uuid4 from "uuid4";

import {
  MessageStateType,
  MessagePayloadType,
  MessageType,
} from "../../types/slices/messages";
import { isRequestExceededError } from "../../utils/request";

const initialState: MessageStateType = [];

export interface CustomPayloadAction<T> extends Action {
  payload: T;
}

const setMessage = createAction("messages/set", function prepare(content) {
  return {
    payload: content,
  };
});

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    unsetMessage(state, action) {
      return state.filter((message) => message.id != action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      "messages/set",
      (state, action: CustomPayloadAction<MessagePayloadType>) => {
        const { content, type, protect } = action.payload;
        let message = content.message;
        const rateExceedError = isRequestExceededError(content);
        if (rateExceedError) {
          message = rateExceedError;
        }
        const splitted_message = message.split(";");
        splitted_message.map((element: string) => {
          state.push({
            id: uuid4(),
            content: element,
            protect: protect,
            type: type,
          });
        });
      }
    );
  },
});

export const { unsetMessage } = messageSlice.actions;
export { setMessage };
export default messageSlice.reducer;
