import React from 'react'
import Skeleton from 'react-loading-skeleton'

import {ReactComponent as Profile} from '../../../assets/icons/profile.svg'
import { useAppSelector } from '../../../app/hooks'

type props = {
    imgDim: number
}

const ImageDisplayer = ({imgDim}:props) => {
    const user = useAppSelector((state) => state.user)
    let src: string
    let notFound:boolean = false
    if (user.info.image_data){
        src = `data:image/png;base64,${user.info.image_data}`
        notFound = false
    }
    else{
        src = require('../../../assets/icons/avatar.png')
        notFound = true
    }
    return (
        <>
        {user.imageIsLoading
                ? <Skeleton circle width={imgDim} height={imgDim} />
                : notFound 
                ? <img src={src}  style={{width:imgDim,height:imgDim,borderRadius:80}}/>
                : <img src={src}  style={{width:imgDim,height:imgDim,borderRadius:80}}/>
            }
        </>
    )
}

export default ImageDisplayer