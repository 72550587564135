import React, { Children } from 'react'
import { Link } from 'react-router-dom'
import './style.css'

type props = {
    children: JSX.Element
}

const Home = ({children}: props) => {
    return (
        <>  
            <div className='mainContainer'>
                <div className='subContainer'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Home