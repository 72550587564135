import React from 'react'
import {Link} from 'react-router-dom'

import {colors} from '../../../constant/colors'
import './style.css'

type props = {
    title : string,
    path: string,
    width: number,
    height: number
}

const NavigatorButton = (props: props) => {
    const {title,path,width,height} = props
    return (
        <Link className='GeneralNavigatorButton' style={{backgroundColor:colors.primary,width:width,height:height}} to={path}>{title}</Link>
    )
}

export default NavigatorButton