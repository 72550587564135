import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import {store} from './app/store'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// For render in strict mode wrap all aplication into <React.StrictMode>
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
