import React from "react";
import { createSlice } from "@reduxjs/toolkit";

import { SystemStateType, ModalVisibilityType, SetModalDonePayloadType } from "../types/slices/system";

const initialState: SystemStateType = {
  dashboardPath: 0,
  registrationSuccess: false,
  systemLoading: false,
  dashboardModalVisible: false,
  dashboardModalComponent: "",
  homeSideBarShow: false,
  modalDone: {status:false,actionType:null},
};
const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setDashboardPath(state, action) {
      state.dashboardPath = action.payload;
    },
    setRegistrationTrue(state) {
      state.registrationSuccess = true;
    },
    setRegisterFalse(state) {
      state.registrationSuccess = false;
    },
    setSystemWaiting(state) {
      state.systemLoading = true;
    },
    setSystemRelease(state) {
      state.systemLoading = false;
    },
    setModalVisiblity(state, action: ModalVisibilityType) {
      state.dashboardModalVisible = action.payload.visibility;
      state.dashboardModalComponent = action.payload.component;
    },
    setModalDone(state, action: SetModalDonePayloadType) {
      const modalDone = {status:true, actionType:action.payload}
      state.modalDone = modalDone
    },
    unsetModalDone(state){
      const modalDone = {status: false, actionType: null}
      state.modalDone = modalDone
    },
    homeSideBarVisibility(state,action){
      state.homeSideBarShow = action.payload
    }
  },
});

export const {
  setDashboardPath,
  setRegistrationTrue,
  setRegisterFalse,
  setSystemWaiting,
  setSystemRelease,
  setModalVisiblity,
  setModalDone,
  unsetModalDone,
  homeSideBarVisibility
} = systemSlice.actions;
export default systemSlice.reducer;
