import React from 'react'
import { MathJaxContext, MathJax } from 'better-react-mathjax'

import './style.css'

type props = {
    element: any
}

const ArticleShow = ({ element }: props) => {
    var config = {
        loader: { load: ['[tex]/enclose'] },
        tex: {
            packages: { '[+]': ['ams'] }
        }
    };
    return (
        <MathJaxContext config={config}>
            <MathJax inline dynamic>
                <div dangerouslySetInnerHTML={{ __html: element }} className='articleRendererContainer' />
            </MathJax>
        </MathJaxContext>
    )
}

export default ArticleShow