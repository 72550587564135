import React from 'react'

import { useAppSelector } from '../../../app/hooks'
import  DeleteModal  from './deleteModal'
import AddTeacherModal from './addTeacherModal'

import './style.css'

const Modal = () => {
    const {dashboardModalVisible} = useAppSelector((state) => state.system)
    return (
        <div className='modalContainer' style={{display:dashboardModalVisible ?'flex':'none'}}>
            <ModalSelector/>
        </div>
    )
}
const ModalSelector = () => {
    const { dashboardModalComponent } = useAppSelector((state) => state.system)
    switch(dashboardModalComponent){
        case 'delete':
            return <DeleteModal/>
        case 'addTeacher':
            return <AddTeacherModal/>
    }
    return <div></div>;
}
export default Modal