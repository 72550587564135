import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Buffer } from "buffer";

import getAxiosInstance from "../../utils/axios";
import { setMessage } from "../messages/messageSlice";
import {
  setComponentMessageFromNetReq,
  setComponentMessage,
} from "../messages/componentMessageSlice";
import { setRegistrationTrue } from "../systemSlice";
import {
  LOGIN_PAGE,
  VALIDATE_TOKEN,
  REGISTER_PAGE,
  IMAGE_RETRIEVE,
  USER_FORGET_PASSWORD,
  REGISTER_BY_ADMIN_PAGE
} from "../../constant/server_address";
import { UserLoginType, UserRegisterPayloadType } from "../../types/slices/users";
import { UserStateType } from "../../types/slices/users";
import { AxiosError, AxiosResponse } from "axios";
import { MessageType } from "../../types/slices/messages";

const axios = getAxiosInstance(false);
const authAxios = getAxiosInstance(true);

export const userLogin = createAsyncThunk(
  "users/fetch",
  async (value: { input: UserLoginType; isRemember: boolean }, thunkAPI) => {
    try {
      thunkAPI.dispatch(userSlice.actions.waitMode());
      const response = (await axios.post(
        LOGIN_PAGE,
        value.input
      )) as AxiosResponse;
      const data = response.data;
      thunkAPI.dispatch(userSlice.actions.loginSuccess(data));
      thunkAPI.dispatch(imageRetrive(response.data.image));
      thunkAPI.dispatch(userSlice.actions.releaseMode());
      if (value.isRemember) {
        localStorage.setItem("token", data.token);
      }
      getAxiosInstance(true); // To renew the auth header by new token
    } catch (err: unknown) {
      const error = err as AxiosError;
      thunkAPI.dispatch(
        setComponentMessageFromNetReq({
          signature: "loginFormMessages",
          context: "generalErrors",
          content: error.response?.data,
        })
      );
      thunkAPI.dispatch(userSlice.actions.releaseMode());
    }
  }
);

export const userRegister = createAsyncThunk(
  "users/create",
  async (value: UserRegisterPayloadType, thunkAPI) => {
    thunkAPI.dispatch(userSlice.actions.waitMode());
    const isRegisterByAdmin = value.registerByAdmin || false
    let address = REGISTER_PAGE
    let signature = "registerFormMessages"
    try {
      if (isRegisterByAdmin){
        address = REGISTER_BY_ADMIN_PAGE
        signature = "addTeacherForm"
      }
      await axios.post(address, value.value);
      thunkAPI.dispatch(setRegistrationTrue());
      thunkAPI.dispatch(userSlice.actions.releaseMode());
      thunkAPI.dispatch(
        setMessage({
          content: {message: "User created successfully"},
          type: MessageType.success,
          protected: false
        })
      )
    } catch (error: any) {
      const err = error as AxiosError;
      const data: any = err.response?.data;
      for (const item of data) {
        thunkAPI.dispatch(
          setComponentMessage({
            signature: signature,
            context: item[1],
            content: item[0],
            type: MessageType.fail,
          })
        );
      }
      thunkAPI.dispatch(userSlice.actions.releaseMode());
    }
  }
);

export const imageRetrive = createAsyncThunk(
  "users/imageRetrieve",
  async (value: string, thunkAPI) => {
    try {
      if (value && value.length > 0) {
        const response = await authAxios.get(`${IMAGE_RETRIEVE}/${value}`, {
          responseType: "arraybuffer",
        });
        return Buffer.from(response.data, "binary").toString("base64");
      }
    } catch (err: any) {
      const result = Buffer.from(err.response.data, "binary").toString("utf8");
      const final_result = { message: result }; // Due to fact that this endpoint return data as arrayBuffer
      // the returned error message doen't follow this structure {"message":"errorMessage"} and just return the error message
      //itself and because setMessage destructure content and expect a object with message key we reconstruct our response.
      thunkAPI.dispatch(
        setMessage({
          content: final_result,
          type: MessageType.fail,
          protect: false,
        })
      );
    }
  }
);
export const checkFromLocalStorage = createAsyncThunk(
  "users/validate",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(userSlice.actions.waitMode());
    const token = localStorage.getItem("token");
    if (token && token.length !== 0) {
      try {
        const response = await authAxios.get(VALIDATE_TOKEN);
        thunkAPI.dispatch(
          userSlice.actions.tokenAcess({ token, userInfo: response.data })
        );
        thunkAPI.dispatch(imageRetrive(response.data.image));
        thunkAPI.dispatch(userSlice.actions.releaseMode());
      } catch (err) {
        thunkAPI.dispatch(userSlice.actions.tokenError());
        thunkAPI.dispatch(userSlice.actions.releaseMode());
      }
    } else {
      thunkAPI.dispatch(userSlice.actions.tokenError());
      thunkAPI.dispatch(userSlice.actions.releaseMode());
    }
  }
);
export const userEdit = createAsyncThunk(
  "users/edit",
  async (value: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(userSlice.actions.waitMode());
      let result: AxiosResponse;
      result = await axios.post("/users/update", value);
      thunkAPI.dispatch(
        setMessage({
          content: { message: "Profile updated" },
          type: MessageType.success,
          protected: true,
        })
      );
      thunkAPI.dispatch(userSlice.actions.changeUsername(result.data.username));
      thunkAPI.dispatch(userSlice.actions.profileEditChangeState(true));
      thunkAPI.dispatch(userSlice.actions.releaseMode());
    } catch (err: unknown) {
      const error = err as AxiosError;
      thunkAPI.dispatch(
        setMessage({
          content: error.response?.data,
          type: MessageType.fail,
          protected: true,
        })
      );
      thunkAPI.dispatch(userSlice.actions.releaseMode());
    }
  }
);
export const imageUpload = createAsyncThunk(
  "users/upload",
  async (value: any, thunkAPI) => {
    try {
      const result = await axios.post("/users/upload", value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      thunkAPI.dispatch(
        setMessage({
          content: result.data,
          type: MessageType.success,
          protected: true,
        })
      );
      thunkAPI.dispatch(userSlice.actions.imageUploadRelease());
    } catch (err: unknown) {
      const error = err as AxiosError;
      thunkAPI.dispatch(
        setMessage({
          content: error.response?.data,
          type: MessageType.fail,
          protected: true,
        })
      );
      thunkAPI.dispatch(userSlice.actions.imageUploadRelease());
    }
  }
);
export const resetPassword = createAsyncThunk(
  "users/reset",
  async (value: any, thunkAPI) => {
    try{

      const result = await axios.post(USER_FORGET_PASSWORD,{email:value})
      thunkAPI.dispatch(userSlice.actions.setSniperLink(result.data.message))
    }
    catch(err: unknown){

    }
  }
)
export const loginFromResetForm = createAsyncThunk(
  "users/loginFromReset",
  async (value: any, thunkAPI) => {
    let headers = {
      Authorization: `Bearer ${value}`,
    };
    try{
      axios.defaults.headers.common = headers;
      axios.get(VALIDATE_TOKEN);
      localStorage.setItem("token",value);
      thunkAPI.dispatch(checkFromLocalStorage())
    }
    catch(err:unknown){
      thunkAPI.dispatch(userSlice.actions.setLoginFromReset(false))
    }
  }
)
const initialState: UserStateType = {
  token: "",
  isAuthenticated: false,
  isLoading: false,
  imageIsLoading: false,
  imageUploading: false,
  loginFromReset: undefined,
  sniperLink : null,
  profileEditSuccess: false,
  info: {
    username: "",
    email: "",
    image: "",
    image_data: "",
    categories: [],
    isAdmin: false,
    id: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem("token");
      state.token = "";
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    tokenError(state) {
      state.token = "";
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    tokenAcess(state, action) {
      const { token, userInfo } = action.payload;
      const info = {
        username: userInfo.username,
        email: userInfo.email,
        image: userInfo.image,
        categories: userInfo.categories,
        isAdmin: userInfo.isAdmin,
        id: userInfo._id,
      };
      state.token = token;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.info = info;
    },
    waitMode(state) {
      state.isLoading = true;
    },
    releaseMode(state) {
      state.isLoading = false;
    },
    imageRetriveError(state) {
      state.imageIsLoading = false;
    },
    loginSuccess(state, action) {
      const { token, username, email, image, categories, _id, isAdmin } = action.payload;
      state.token = token;
      state.isAuthenticated = true;
      state.isLoading = false;
      const info = {
        username,
        email,
        image,
        categories,
        isAdmin,
        id: _id,
      };
      state.info = info;
    },
    changeImage(state, action) {
      state.info.image_data = action.payload;
    },
    changeUsername(state, action) {
      state.info.username = action.payload;
    },
    imageUploadRelease(state) {
      state.imageUploading = false;
    },
    profileEditChangeState(state, action) {
      state.profileEditSuccess = action.payload;
    },
    setLoginFromReset(state,action){
      state.loginFromReset = action.payload
    },
    setSniperLink(state,action){
      state.sniperLink = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(imageRetrive.fulfilled, (state, action) => {
      state.info.image_data = action.payload;
      state.imageIsLoading = false;
    });
    builder.addCase(imageRetrive.pending, (state, action) => {
      state.imageIsLoading = true;
    });
    builder.addCase(userEdit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(imageUpload.pending, (state, action) => {
      state.imageUploading = true;
    });
  },
});

export const { logout, tokenError, changeImage, profileEditChangeState, setSniperLink } =
  userSlice.actions;
export default userSlice.reducer;
