import React from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'

const NotFound = () => {
    const navigate = useNavigate()
    const goHomeHandler = () => {
        navigate('/')
    }
    return (
        <div className='notFoundContainer'>
            <span className='notFoundFirstText'>404</span>
            <span className='notFoundSecondText'>Sorry we can’t find the page you’re looking for </span>
            <div className='primaryButton goHomeButton' onClick={goHomeHandler}>Go Home</div>

        </div>
    )
}

export default NotFound