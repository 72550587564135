import Quill from "quill";
import React from "react";
import { v4 } from "uuid";


const BlockEmbed = Quill.import("blots/block/embed");

Quill.debug('error')
export class ImageBlot extends BlockEmbed {
  static blotName = "custom_img";
  static tagName = "img";
  static className = `customImage`;
  static ref = {};

  static create(value) {
    const {src} = value
    const id = v4();
    let node = super.create(value);
    const refs = ImageBlot.refs;
    node.setAttribute("data_id", id);
    node.setAttribute("src", src);
    // node.setAttribute("url",url)
    ImageBlot.data = value;
    ImageBlot.refs = {
      ...refs,
      [id]: React.createRef()
    };
    return node;
  }
}
