export function articleImagesOrganizer(articleBody: any): void {
  const imgElementCollection = articleBody.querySelectorAll(".customImage");
  if (imgElementCollection) {
    imgElementCollection.forEach((item: any) => {
      const url = item.getAttribute("url") as string;
      const src = item.getAttribute("src") as string;
      if (url === null || src === null) {
        return;
      }
      item.setAttribute("src", url);
      item.removeAttribute("url");
    });
  }
}

export function articleImageList(
  articleBody: any,
  imageList: React.MutableRefObject<string[]>
): void {
  const imgElementCollection = articleBody.querySelectorAll(".customImage");
  if (imgElementCollection) {
    imgElementCollection.forEach((item: any) => {
      const src = item.getAttribute("src") as string;
      const splittedUrl = src.split("/");
      const value = splittedUrl[splittedUrl.length - 1];
      imageList.current.push(value);
    });
  }
}
