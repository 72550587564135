import React from 'react'

import { ComponentMessage } from '../../'

import { ComponentMessageTypeBase } from '../../../types/slices/messages'

import './style.css'

type props = {
    changeHandler:  (e: React.FormEvent<HTMLInputElement>) => void
    value: string
    isRequired: boolean,
    fieldName?: string,
    type: string,
    errorMessage: ComponentMessageTypeBase[],
    labelColor?: string,
    title: string,

}

const InputWithLabel = (props: props) => {
    const {changeHandler,value, title, isRequired, type, errorMessage, labelColor, fieldName} = props
    return (
        <div className="input-group">
            {errorMessage ? <ComponentMessage messages={errorMessage}/> : null}
            <input type={type} name={fieldName || title} onChange={changeHandler} className={`user-input ${errorMessage.length > 0 ? 'error':''}`} />
            <label style={{transform:value.length ? 'translate(0px, 0px)': '',backgroundColor:labelColor || ''}} htmlFor={title} className="user-label">
                {title}<span style={{color:'red'}}>{isRequired ?'*':null}</span>
            </label>
        </div>
    )
}
export default InputWithLabel