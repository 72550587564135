//  This slice doest not have any export slice in it. It just define a async function and dispatch message slice

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import getAxiosInstance from "../../utils/axios";
import { ARTICLE_CREATE } from "../../constant/server_address";
import { setMessage } from "../messages/messageSlice";
import { setComponentMessageFromNetReq } from "../messages/componentMessageSlice";
import {
  TEACHER_ARTICLES_LIST,
  TEACHER_DELETE_ARTICLE,
  TEACHER_UPDATE_ARTICLE,
} from "../../constant/server_address";

import { MessageType } from "../../types/slices/messages";
import {
  CreateArticlePayload,
  TeacherSliceInitilaState,
  TeacherGetArticlePayload,
  TeacherArticleDeletePayload,
  TeacherArticleUpdatePayload,
} from "../../types/slices/articles";

import { AxiosError } from "axios";

const authAxios = getAxiosInstance(true);

const initialState: TeacherSliceInitilaState = {
  content: [],
  loading: false,
  createArticle: false,
  articleUpdated: false,
};
export const deleteTeacherArticle = createAsyncThunk(
  "teacherArticle/delete",
  async (value: TeacherArticleDeletePayload, thunkAPI) => {
    try {
      const result = await authAxios.delete(
        TEACHER_DELETE_ARTICLE + `/${value.id}`
      );
      const prevArticles = thunkAPI.getState() as any;
      let newArticle: [];
      newArticle = prevArticles.teacherArticle.content.filter(
        (element: { [_id: string]: string }) => {
          return element._id !== value.id;
        }
      );
      thunkAPI.dispatch(
        setMessage({
          content: result.data,
          type: MessageType.success,
        })
      );
      return { value: newArticle };
    } catch (err: unknown) {
      const error = err as AxiosError;
      thunkAPI.dispatch(
        setMessage({
          content: error.response?.data,
          type: MessageType.fail,
        })
      );
    }
  }
);
export const createArticle = createAsyncThunk(
  "teacherArticle/create",
  async (value: CreateArticlePayload, thunkAPI) => {
    try {
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(true));
      const { hashtags } = value;
      const modifiedHashtags = [];
      let item: any = {};
      for (item of hashtags) {
        modifiedHashtags.push(item.name);
      }
      value = { ...value, hashtags: modifiedHashtags };
      const response = await authAxios.post(ARTICLE_CREATE, value);
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(false));
      thunkAPI.dispatch(teacherSlice.actions.setCreateArticle(true));
      thunkAPI.dispatch(
        setMessage({
          content: response.data,
          type: MessageType.success,
        })
      );
    } catch (error: unknown) {
      const err = error as AxiosError;
      thunkAPI.dispatch(
        setComponentMessageFromNetReq({
          signature: "publishFormMessages",
          context: "general",
          content: err.response?.data,
        })
      );
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(false));
    }
  }
);
export const getTeacherArticles = createAsyncThunk(
  "teacherArticle/fetch",
  async (value: TeacherGetArticlePayload, thunkAPI) => {
    try {
      const result = await authAxios.post(TEACHER_ARTICLES_LIST, {
        published: value.published,
        searchKey: value.searchKey,
      });
      return result;
    } catch (err) {
      thunkAPI.dispatch(
        setMessage({
          content: { message: "Problem loading article" },
          type: MessageType.fail,
          protected: true,
        })
      );
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(false));
    }
  }
);
export const articleUpdate = createAsyncThunk(
  "teacherArticle/update",
  async ({ id, value }: TeacherArticleUpdatePayload, thunkAPI) => {
    const allUpdate = Object.keys(value).length === 7;
    try {
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(true));
      if (allUpdate) {
        // whole element of article will be updated
        const { hashtags } = value as any;
        const modifiedHashtags = [];
        let item: any = {};
        for (item of hashtags) {
          modifiedHashtags.push(item.name);
        }
        value = { ...value, hashtags: modifiedHashtags };
      }
      const result = await authAxios.put(
        TEACHER_UPDATE_ARTICLE + `/${id}`,
        {
          value,
        },
        { timeout: 5000 }
      );
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(false));
      if (allUpdate) {
        thunkAPI.dispatch(teacherSlice.actions.setUpdateArticle(true));
        thunkAPI.dispatch(
          setMessage({
            content: { message: "Article Updated" },
            type: MessageType.success,
            protected: true,
          })
        );
      } else {
        const { teacherArticle } = thunkAPI.getState() as any;
        const remainingArticles = teacherArticle.content.filter(
          (item: any) => item._id !== id
        );
        thunkAPI.dispatch(
          teacherSlice.actions.updateArticleList(remainingArticles)
        );
      }
    } catch (err) {
      thunkAPI.dispatch(teacherSlice.actions.setArticleLoading(false));
    }
  }
);

const teacherSlice = createSlice({
  name: "teacherSlice",
  initialState,
  reducers: {
    setCreateArticle(state, action) {
      state.createArticle = action.payload;
    },
    setArticleLoading(state, action) {
      state.loading = action.payload;
    },
    setUpdateArticle(state, action) {
      state.articleUpdated = action.payload;
    },
    updateArticleList(state, action) {
      state.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeacherArticles.fulfilled, (state, action) => {
      const { content } = action.payload?.data;
      state.content = content;
      state.loading = false;
    });
    builder.addCase(getTeacherArticles.pending, (state, action) => {
      state.loading = true;
    });
    //
    builder.addCase(deleteTeacherArticle.fulfilled, (state, action) => {
      const newArticle = action.payload;
      if (newArticle?.value) {
        state.content = newArticle?.value;
      }
    });
  },
});

export const { setCreateArticle, setUpdateArticle } = teacherSlice.actions;
export default teacherSlice.reducer;
